import { ParentState } from "@hopper-b2b/checkout";
import { assign } from "xstate";
import { ApplePayEventType, type SetApplePayPayment } from "./events";
import type { PartialParentContextWithApplePay } from "./types";

export const actions = {
  [ApplePayEventType.ADD_APPLE_PAY_PAYMENT_GO_NEXT]: assign(
    (context: PartialParentContextWithApplePay, event: SetApplePayPayment) => {
      context[ParentState.applePay].paymentData = event.paymentData;
      return context;
    }
  ),
};
