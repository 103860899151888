import {
  type LogsEvent,
  type LogsInitConfiguration,
  datadogLogs,
} from "@datadog/browser-logs";
import { CLIENT_TOKEN, DATADOG_SITE } from "./constants";

export type InitializeLogsProps = Partial<LogsInitConfiguration> & {
  service: string;
  sessionId?: string;
  /** This function should mutate the log.
   * An example to append the session id used in amplitude:
   * const appendLog(log: LogsEvent) => { log.hts_session_id = getHTSSessionIdCookie() ?? ""; }
   */
  appendLogAttributes?: (log: LogsEvent) => void;
};

export const initializeLogs = ({
  sessionId,
  service,
  appendLogAttributes,
  ...rest
}: InitializeLogsProps) => {
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: DATADOG_SITE,
    service,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    trackSessionAcrossSubdomains: true,
    beforeSend: (log: LogsEvent) => {
      if (appendLogAttributes != null) {
        appendLogAttributes(log);
      }
      log.browser_session_id = sessionId;
      log.path = window.location.pathname;
      return true;
    },
    ...rest,
  });
};
