import React, { type ReactNode } from "react";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import defaultStyles from "./styles.module.scss";

export type InputContainerProps = {
  id: string;
  label?: string;
  hasValue?: boolean;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  startContent?: ReactNode;
  endContent?: ReactNode;
  disabled?: boolean;
} & PlatformComponentProps;

const COMPONENT_KEY = CoreUiComponents.InputContainer;

export const InputContainer: React.FC<InputContainerProps> = ({
  id,
  label,
  className,
  startContent,
  endContent,
  children,
  hasValue = false,
  hasError = false,
}) => {
  new Error();
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, {
    error: hasError,
    filled: hasValue,
  });

  return (
    <div className={clsx(block, className)}>
      <div className={cn("content")}>
        {startContent != null ? (
          <div className={cn("start-content")}>{startContent}</div>
        ) : null}
        <div className={cn("input-root")}>
          {label != null ? (
            <label htmlFor={id} className={cn("label")}>
              {label}
            </label>
          ) : null}
          <div className={cn("input-content")}>{children}</div>
        </div>
        {endContent != null ? (
          <div className={cn("end-content")}>{endContent}</div>
        ) : null}
      </div>
    </div>
  );
};

export const InputError: React.FC<{ error?: Error }> = ({ error }) => {
  return error != null ? <div className={"error"}>{error.message}</div> : null;
};
