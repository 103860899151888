import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  TripCategory,
  type ITripTerminus,
  type IPassengerCounts,
  type AirlineCode,
  type FareclassOptionFilter,
  type IFlightNumberFilter,
  type ITimeRange,
  SliceStopCountFilter,
  type MobileFlightSearchDialogSteps,
  type FlightPricePrediction,
  type SerializableMonthBucket,
} from "@b2bportal/core-types";
import { initializeSearchState } from "./thunks/fetchSearchState";
import { fetchCalendar } from "./thunks";
import type { Flight, Suggestion } from "@b2bportal/air-shopping-api";
import { setSearchFormFromItinerary } from "../../../store";

export type FlightLocationSuggestion = Suggestion & {
  id: { Id: "Flight" } & Flight;
};

export interface IFilterState {
  stopsOption: SliceStopCountFilter;
  maxPriceFilter: number;
  fareclassOptionFilter: FareclassOptionFilter;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  airlineFilter: AirlineCode[];
  airportFilter: string[];
  flightNumberFilter: IFlightNumberFilter[];
}

export interface FlightSearchValues {
  tripCategory: TripCategory;
  origin?: ITripTerminus;
  destination?: ITripTerminus;
  departureDate?: string;
  returnDate?: string;
  passengerCounts: {
    adultsCount: number;
    childrenCount: number;
    infantsInSeatCount: number;
    infantsOnLapCount: number;
  };
}

export type StoreFlightPricePrediction = FlightPricePrediction & {
  departureMonths: SerializableMonthBucket[];
};

export interface FlightSearchState extends FlightSearchValues, IFilterState {
  isInitializingValues?: boolean;
  searchFormValues: FlightSearchValues;
  mobileSearchActiveStep: MobileFlightSearchDialogSteps | null;
  pricePrediction: StoreFlightPricePrediction;
}

export const INITIAL_MAX_PRICE = 0;

export const MAXIMUM_COUNT = 6;
export const MINIMUM_ADULT_COUNT = 1;
export const TIME_RANGE_STEP = 360;
export const TIME_RANGE_MAX = 1440;

const initialTimeRange: ITimeRange = {
  min: 0,
  // note: time measured in minutes
  max: TIME_RANGE_MAX,
};

export const initialFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: false,
  enhanced: false,
  premium: false,
  luxury: false,
};

export const initialFilterOptions: IFilterState = {
  stopsOption: SliceStopCountFilter.ANY_NUMBER,
  maxPriceFilter: Number.MAX_SAFE_INTEGER,
  fareclassOptionFilter: initialFareclassOptionFilter,
  outboundDepartureTimeRange: initialTimeRange,
  outboundArrivalTimeRange: initialTimeRange,
  returnDepartureTimeRange: initialTimeRange,
  returnArrivalTimeRange: initialTimeRange,
  airlineFilter: [],
  airportFilter: [],
  flightNumberFilter: [],
};

export const initialPricePrediction: StoreFlightPricePrediction = {
  departureMonths: [],
  priceTags: [],
  isLoading: false,
};

export const initialSearchState: FlightSearchState = {
  tripCategory: TripCategory.ROUND_TRIP,
  passengerCounts: {
    adultsCount: 1,
    childrenCount: 0,
    infantsInSeatCount: 0,
    infantsOnLapCount: 0,
  },
  // Need a way to differenciate between "has not started initializeing" and "has finished initializing"
  isInitializingValues: undefined,
  searchFormValues: {
    tripCategory: TripCategory.ROUND_TRIP,
    passengerCounts: {
      adultsCount: 1,
      childrenCount: 0,
      infantsInSeatCount: 0,
      infantsOnLapCount: 0,
    },
  },
  mobileSearchActiveStep: null,
  pricePrediction: initialPricePrediction,
  ...initialFilterOptions,
};

export const flightSearchSlice = createSlice({
  name: "flightSearch",
  initialState: initialSearchState,
  reducers: {
    setTripCategory: (state, action: PayloadAction<TripCategory>) => {
      state.tripCategory = action.payload;
    },
    setOrigin: (state, action: PayloadAction<ITripTerminus>) => {
      state.origin = action.payload;
    },
    setDestination: (state, action: PayloadAction<ITripTerminus>) => {
      state.destination = action.payload;
    },
    setDepartureDate: (state, action: PayloadAction<string>) => {
      state.departureDate = action.payload;
    },
    setReturnDate: (state, action: PayloadAction<string>) => {
      state.returnDate = action.payload;
    },
    setPassengerCount: (state, action: PayloadAction<IPassengerCounts>) => {
      Object.assign(state, action.payload);
    },
    setFlightSearch: (
      state,
      action: PayloadAction<Partial<FlightSearchValues>>
    ) => {
      Object.assign(state, action.payload);
    },
    setStopsOption(state, action: PayloadAction<SliceStopCountFilter>) {
      state.stopsOption = action.payload;
    },
    setMaxPriceFilter(state, action: PayloadAction<number>) {
      state.maxPriceFilter = action.payload;
    },
    setFareclassOptionFilter(
      state,
      action: PayloadAction<FareclassOptionFilter>
    ) {
      state.fareclassOptionFilter = action.payload;
    },
    setOutboundDepartureTimeRange(state, action: PayloadAction<ITimeRange>) {
      state.outboundDepartureTimeRange = action.payload;
    },
    setOutboundArrivalTimeRange(state, action: PayloadAction<ITimeRange>) {
      state.outboundArrivalTimeRange = action.payload;
    },
    setReturnDepartureTimeRange(state, action: PayloadAction<ITimeRange>) {
      state.returnDepartureTimeRange = action.payload;
    },
    setReturnArrivalTimeRange(state, action: PayloadAction<ITimeRange>) {
      state.returnArrivalTimeRange = action.payload;
    },
    setAirlineFilter(state, action: PayloadAction<AirlineCode[]>) {
      state.airlineFilter = action.payload;
    },
    setAirportFilter(state, action: PayloadAction<string[]>) {
      state.airportFilter = action.payload;
    },
    setFlightNumberFilter(state, action: PayloadAction<IFlightNumberFilter[]>) {
      state.flightNumberFilter = action.payload;
    },
    resetFilters(state) {
      Object.assign(state, initialFilterOptions);
    },
    setAllFilters(state, action: PayloadAction<IFilterState>) {
      Object.assign(state, action.payload);
    },
    setSearchFormValues(state, action: PayloadAction<FlightSearchValues>) {
      state.searchFormValues = action.payload;
    },
    setSearchFormTripCategory: (state, action: PayloadAction<TripCategory>) => {
      state.searchFormValues.tripCategory = action.payload;
    },
    setSearchFormOrigin: (state, action: PayloadAction<ITripTerminus>) => {
      state.searchFormValues.origin = action.payload;
    },
    setSearchFormDestination: (state, action: PayloadAction<ITripTerminus>) => {
      state.searchFormValues.destination = action.payload;
    },
    setSearchFormDepartureDate: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.searchFormValues.departureDate = action.payload;
    },
    setSearchFormReturnDate: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.searchFormValues.returnDate = action.payload;
    },
    setSearchFormPassengerCount: (
      state,
      action: PayloadAction<IPassengerCounts>
    ) => {
      Object.assign(state.searchFormValues.passengerCounts, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchFlights
      .addCase(initializeSearchState.pending, (state) => {
        state.isInitializingValues = true;
      })
      .addCase(initializeSearchState.fulfilled, (state, action) => {
        state.isInitializingValues = false;
        Object.assign(state, action.payload);
        Object.assign(state.searchFormValues, action.payload);
      })
      .addCase(initializeSearchState.rejected, (state) => {
        state.isInitializingValues = false;
      })
      .addCase(fetchCalendar.pending, (state) => {
        state.pricePrediction.priceTags = [];
        state.pricePrediction.departureMonths = [];
        state.pricePrediction.isLoading = true;
      })
      .addCase(fetchCalendar.fulfilled, (state, action) => {
        Object.assign(state.pricePrediction, action.payload);
        state.pricePrediction.isLoading = false;
      })
      .addCase(fetchCalendar.rejected, (state) => {
        state.pricePrediction = initialPricePrediction;
      })
      .addCase(setSearchFormFromItinerary, (state, action) => {
        Object.assign(state.searchFormValues, action.payload.searchFormValues);
      });
  },
});

export const {
  setTripCategory,
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  setPassengerCount,
  setStopsOption,
  setMaxPriceFilter,
  setFareclassOptionFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setAirlineFilter,
  setAirportFilter,
  setFlightNumberFilter,
  setSearchFormValues,
  setSearchFormOrigin,
  setSearchFormDestination,
  setSearchFormTripCategory,
  setSearchFormPassengerCount,
  setSearchFormDepartureDate,
  setSearchFormReturnDate,
} = flightSearchSlice.actions;

export const FlightSearchActions = flightSearchSlice.actions;
export default flightSearchSlice.reducer;
