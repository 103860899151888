export const CyCheckoutReviewSelectors = {
  PRICE_BREAKDOWN_BASE_AMOUNT: "checkout-review-price-breakdown-base-amount",
  PRICE_BREAKDOWN_TAXES_AND_FEES_AMOUNT:
    "checkout-review-price-breakdown-taxes-and-fees-amount",
  PRICE_BREAKDOWN_CFAR_AMOUNT: "checkout-review-price-breakdown-cfar-amount",
  PRICE_BREAKDOWN_REWARDS_AMOUNT:
    "checkout-review-price-breakdown-rewards-amount",
  PRICE_BREAKDOWN_OFFERS_AMOUNT:
    "checkout-review-price-breakdown-offers-amount",
  PRICE_BREAKDOWN_DUE_UPON_CHECKIN_AMOUNT:
    "checkout-review-price-breakdown-due-upon-checkin-amount",
  PRICE_BREAKDOWN_PRICE_FREEZE_AMOUNT:
    "checkout-review-price-breakdown-price-freeze-amount",
  PRICE_BREAKDOWN_TOTAL_AMOUNT: "checkout-review-price-breakdown-total-amount",
  PRICE_BREAKDOWN_DUE_TODAY: "checkout-review-price-breakdown-due-today",
};
