import React, { useMemo, useId, useState, type ReactNode } from "react";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { InputContainer } from "@components/ui";
import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";
import { Icon } from "../Icon";
import defaultStyles from "./Select.module.scss";

export interface SelectComponentProps extends SelectPrimitive.SelectProps {
  placeholder?: React.ReactNode;
  id?: string;
  label?: string;
  // Radix Content prop
  contentProps?: SelectPrimitive.SelectContentProps;
  className?: string;
  valueToDisplay?: string;
  dropdownIconName?: IconNameEnum;
  startIcon?: ReactNode;
}

export const Select = React.forwardRef<HTMLButtonElement, SelectComponentProps>(
  (
    {
      children,
      placeholder,
      valueToDisplay,
      contentProps,
      className,
      dropdownIconName,
      id,
      label,
      startIcon,
      ...props
    },
    forwardedRef
  ) => {
    const COMPONENT_KEY = CoreUiComponents.Select;
    const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
    const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

    const reactId = useId();
    const internalId = useMemo(() => {
      return id ?? reactId;
    }, [id, reactId]);

    const [internalValue, setInternalValue] = useState(
      props.value ?? props.defaultValue
    );
    const hasValue = useMemo(() => {
      return internalValue != null && internalValue !== "";
    }, [internalValue]);

    return (
      <SelectPrimitive.Root
        {...props}
        onValueChange={(e) => {
          setInternalValue(e);
          props.onValueChange?.(e);
        }}
      >
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          className={clsx(cn("trigger"), className)}
        >
          <InputContainer
            className={cn("input-container")}
            id={internalId}
            label={label}
            hasValue={hasValue}
            startContent={startIcon}
            endContent={
              <Icon
                iconName={dropdownIconName ?? IconNameEnum.dropdownTriangle}
                className={cn("trigger-icon")}
              />
            }
          >
            <div
              className={cn("trigger-label", {
                "select-label": label != null,
              })}
            >
              <SelectPrimitive.Value
                placeholder={placeholder}
                aria-label={valueToDisplay}
              >
                {valueToDisplay}
              </SelectPrimitive.Value>
            </div>
          </InputContainer>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={cn("content")}
            position="popper"
            sideOffset={5}
            arrowPadding={5}
            {...contentProps}
          >
            <SelectPrimitive.ScrollUpButton className={cn("scroll-button")}>
              <Icon
                iconName={IconNameEnum.chevronUp}
                className={cn("scroll-icon")}
              />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className={cn("view")}>
              {children}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className={cn("scroll-button")}>
              <Icon
                iconName={IconNameEnum.chevronDown}
                className={cn("scroll-icon")}
              />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  }
);

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  SelectPrimitive.SelectItemProps
>(({ children, className, ...props }, forwardedRef) => {
  const COMPONENT_KEY = CoreUiComponents.Select;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <SelectPrimitive.Item
      {...props}
      ref={forwardedRef}
      className={clsx(cn("item"), className)}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <SelectPrimitive.ItemIndicator>
        <Icon
          iconName={IconNameEnum.check}
          className={cn("item-indicator-icon")}
        />
      </SelectPrimitive.ItemIndicator>
    </SelectPrimitive.Item>
  );
});
