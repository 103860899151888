import dayjs from "dayjs";
import clsx from "clsx";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./DatePickerButton.module.scss";
import { Icon } from "@components/ui";
import { forwardRef } from "react";
import { useI18nContext, type DateTimeFormatStyle } from "@hopper-b2b/i18n";

export interface DatePickerButtonProps {
  dateFormat?: DateTimeFormatStyle;
  startDate?: string;
  endDate?: string;
  disabled?: boolean;
  startLabel: string;
  endLabel: string;
  onClick?: () => void;
  hideEndDate?: boolean;
  hideSeparator?: boolean;
  ariaLabel?: string;
  className?: string;
  error?: Error;
}

interface ContentProps {
  label: string;
  date?: string;
}

export const DatePickerButton = forwardRef<
  HTMLButtonElement,
  DatePickerButtonProps
>((props, ref) => {
  const {
    startDate,
    endDate,
    startLabel,
    endLabel,
    disabled,
    onClick,
    dateFormat,
    hideEndDate,
    hideSeparator,
    ariaLabel,
    className,
    error,
  } = props;

  const COMPONENT_KEY = CoreUiComponents.DatePickerButton;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, {
    error: error != null,
  });
  const { formatDateTime, DateTimeFormatStyle } = useI18nContext();
  const defaultDateTimeFormat = DateTimeFormatStyle.ShortMonthDayShortWeekday;

  const calendarIcon = (
    <Icon className={cn("icon")} iconName={IconNameEnum.calendar} />
  );

  const Content = ({ date, label }: ContentProps) => (
    <div className={cn("button-content")}>
      <div className={cn("content-top-label")}>{date ? label : ""}</div>
      <div
        className={clsx(
          cn("content-main-content", { label: !date, error: error != null })
        )}
      >
        {date
          ? formatDateTime(
              dayjs(date).toDate(),
              dateFormat ?? defaultDateTimeFormat
            )
          : label}
      </div>
    </div>
  );

  const startDateComponent = (
    <div className={cn("start-date-container")}>
      {calendarIcon}
      <Content date={startDate} label={startLabel} />
    </div>
  );

  const endDateComponent = (
    <div className={cn("end-date-container")}>
      {calendarIcon}
      <Content date={endDate} label={endLabel} />
    </div>
  );

  const separatorIcon = (
    <div className={cn("separator-container")}>
      <Icon iconName={IconNameEnum.calendarSeparatorIcon} />
    </div>
  );

  return (
    <button
      className={clsx(block, className)}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
      type="button"
      ref={ref}
    >
      {startDateComponent}
      {!hideEndDate && !hideSeparator && separatorIcon}
      {!hideEndDate && endDateComponent}
    </button>
  );
});
