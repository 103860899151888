import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { IApiConfig } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";

import { analyticsApiPrefix } from "../paths";

export const trackEvent = (
  request: TrackEventRequest,
  config?: IApiConfig
): Promise<boolean> => {
  const analyticsApi = config?.analyticsApiPrefix || analyticsApiPrefix;
  const END_POINT = `${analyticsApi}/event`;

  const req: TrackEventRequest = request.eventName.startsWith("w1_")
    ? request
    : {
        ...request,
        eventName: `w1_${request.eventName}`,
      };

  return new Promise(async (resolve) => {
    try {
      const res = await axiosInstance.post(END_POINT, req);
      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      // Don't reject promise to prevent ad block from interfering with api calls
      resolve(false);
    }
  });
};
