export const CORE_FINTECH_UI_NAME = "core-fintech-ui";

export enum CoreFintechUiComponents {
  FintechProduct = "FintechProduct",
  FintechProductBullet = "FintechProductBullet",
  FintechProductOptionsSection = "FintechProductOptionsSection",
  FintechProductInfoLinksSection = "FintechProductInfoLinksSection",
  FintechMarketplaceSection = "FintechMarketplaceSection",
  FintechMarketplacePage = "FintechMarketplacePage",
}
