export const CORE_UI_NAME = "core-ui";

export enum CoreUiComponents {
  AirlineIcon = "AirlineIcon",
  Breadcrumbs = "Breadcrumbs",
  Button = "Button",
  Calendar = "Calendar",
  Card = "Card",
  Carousel = "Carousel",
  CarouselSliderDots = "CarouselSliderDots",
  Checkbox = "Checkbox",
  CheckboxButton = "CheckboxButton",
  CheckboxCard = "CheckboxCard",
  CheckboxGroup = "CheckboxGroup",
  CurrencySelector = "CurrencySelector",
  DatePickerButton = "DatePickerButton",
  Dialog = "Dialog",
  Drawer = "Drawer",
  FilterDialogContainer = "FilterDialogContainer",
  FilterPrice = "FilterPrice",
  Homepage = "Homepage",
  Icon = "Icon",
  IframeDialog = "IframeDialog",
  Image = "Image",
  ImportantInfoList = "ImportantInfoList",
  InfoBanner = "InfoBanner",
  ItineraryCardHeader = "ItineraryCardHeader",
  ItineraryCardNotification = "ItineraryCardNotification",
  LanguageSelector = "LanguageSelector",
  LazyLoading = "LazyLoading",
  ListError = "ListError",
  ListItem = "ListItem",
  Loading = "Loading",
  LocationAutocomplete = "LocationAutocomplete",
  Logo = "Logo",
  Map = "Map",
  MapMarker = "MapMarker",
  NotificationBanner = "NotificationBanner",
  Page = "Page",
  PageContent = "PageContent",
  PageFooter = "PageFooter",
  PageHeader = "PageHeader",
  PageLoadingScreen = "PageLoadingScreen",
  PhoneInputField = "PhoneInputField",
  Pill = "Pill",
  Popover = "Popover",
  PriceBreakdown = "PriceBreakdown",
  PriceDisplay = "PriceDisplay",
  PriceField = "PriceField",
  RadioGroup = "RadioGroup",
  RadioItem = "RadioItem",
  SearchAutocomplete = "SearchAutocomplete",
  Select = "Select",
  Slider = "Slider",
  StarRating = "StarRating",
  Switch = "Switch",
  TextField = "TextField",
  TripadvisorRatingIcon = "TripadvisorRatingIcon",
  TripsAppPage = "TripsAppPage",
  WalletPage = "WalletPage",
  TimePicker = "TimePicker",
  InputContainer = "InputContainer",
  InputText = "InputText",

  // Calendar
  CalendarPriceRangeTags = "CalendarPriceRangeTags",
  DatePickerColumnView = "DatePickerColumnView",
  DatePickerDay = "DatePickerDay",
  DatePickerGroupView = "DatePickerGroupView",
  DatePickerHorizontalView = "DatePickerHorizontalView",
  DatePickerMonth = "DatePickerMonth",
  MonthAndDatePicker = "MonthAndDatePicker",
}
