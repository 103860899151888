export enum ApplePayEventType {
  ADD_APPLE_PAY_PAYMENT_GO_NEXT = "ADD_APPLE_PAY_PAYMENT_GO_NEXT",
}

export interface SetApplePayPayment {
  type: ApplePayEventType.ADD_APPLE_PAY_PAYMENT_GO_NEXT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentData: any; //Opaque to FE
}

export type ApplePayEvents = SetApplePayPayment;
