import type { TrackingPropertiesType } from "@hopper-b2b/types";
import {
  USER_CAMPAIGN_KEY,
  USER_MEDIUM_KEY,
  ACCEPT_LANGUAGE_HEADER,
  getAcceptLanguagesFromLocale,
  getDeviceData,
  getUserDeviceData,
  useNavigate,
  USER_CONTENT_KEY,
} from "@hopper-b2b/utilities";

import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  HttpStatusCode,
} from "axios";
import { type ReactNode, useCallback, useEffect } from "react";
import {
  DEVICE_ID_HEADER,
  getDeviceId,
  validateAndSetDeviceId,
} from "./deviceId";
import { logger } from "./logger";
import {
  handleMaxRetryTimesExceeded,
  handleRetry,
  setCurrentState,
  shouldRetry,
  exponentialDelay,
} from "./AxiosRetry";

const USER_SOURCE_KEY = "user_source";
const PORTAL_UNAUTHORIZED_PATH = "/auth/invalidsession/";

const apiVersionPrefix = "/api/v0";
const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
const analyticsEventApi = `${analyticsApiPrefix}/event`;

export interface ClientHintHeaders {
  "X-Accept-Currency"?: string;
  // https://wicg.github.io/responsive-image-client-hints/#sec-ch-width
  // Remove when sec-ch-width is released and supported
  "X-Sec-CH-Viewport-Width"?: number;
}

export interface Headers extends ClientHintHeaders {
  "Content-Type"?: string;
  "Hopper-Session"?: string;
  "Tenant-Session"?: string;
}

export interface AxiosInterceptorsProps {
  children?: ReactNode;
  trackingProperties: TrackingPropertiesType | undefined;
  additionalTrackingProperties?: TrackingPropertiesType | undefined;
  userSource: string | null;
  userMedium?: string;
  userCampaign?: string;
  userContent?: string;
  isSignedIn?: boolean;
  isGuestUser?: boolean;
  delegatedTo?: string;
  tenant: string;
  locale: string;
  currency: string;
  requestHeaders: Headers;
  version: string;
  retryTransientErrors?: boolean;
  logRequest?: (res: AxiosRequestConfig) => void;
  logResponse?: (res: AxiosResponse) => void;
  logError?: (error: AxiosError) => void;
  errorAction?: (error: AxiosError) => Promise<AxiosError | AxiosResponse>;
}

function addTrackingProperties(
  trackingProperties: TrackingPropertiesType | undefined,
  properties?: object
): object {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = [
      ...(Array.isArray(properties["experiments"])
        ? properties["experiments"]
        : []),
      ...Object.keys(trackingProperties).map(
        (key: string) => `${key}_${trackingProperties[key]}`
      ),
    ];
  }

  return properties;
}

export const axiosInstance: AxiosInstance = axios.create();

// We don't know the type of properties passed here from axios interceptors
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isReadonly = (obj: any, prop: string): boolean => {
  try {
    const originalValue = obj[prop];
    obj[prop] = originalValue; // Attempt to modify the property
    return false;
  } catch (error) {
    return true;
  }
};

const unpackTrackingRequestData = (data: unknown) => {
  if (typeof data === "object" || typeof data !== "string") {
    return data;
  }

  try {
    return JSON.parse(data);
  } catch (e) {
    logger() && logger().error("Tracking data could not be parsed: ", e);
  }
  return data;
};

export const AxiosInterceptors = ({
  children,
  delegatedTo,
  isSignedIn,
  isGuestUser,
  tenant,
  locale,
  currency,
  trackingProperties,
  additionalTrackingProperties,
  userSource,
  userMedium,
  userCampaign,
  userContent,
  requestHeaders,
  version,
  retryTransientErrors = false,
  logRequest,
  logResponse,
  logError,
  errorAction,
}: AxiosInterceptorsProps) => {
  const navigate = useNavigate();

  const handleReq = useCallback(
    (req) => {
      const deviceId = getDeviceId();

      const headers: Headers = {
        "Content-Type": "application/json",
        ...(deviceId ? { [DEVICE_ID_HEADER]: deviceId } : {}),
        ...(locale
          ? {
              [ACCEPT_LANGUAGE_HEADER]:
                getAcceptLanguagesFromLocale(locale).join(","),
            }
          : {}),
        ...requestHeaders,
      };
      let properties = {};
      if (req.url === analyticsEventApi) {
        // We need to unpack the data here because the data may be stringified in the request
        req.data = unpackTrackingRequestData(req.data);
        properties = {
          ...req.data.properties,
          ...getUserDeviceData(),
          device_type: getDeviceData().type,
          is_agent_session: Boolean(delegatedTo),
          delegated_to: delegatedTo,
          guest_user: isGuestUser,
          signed_in: isSignedIn,
          locale,
          currency,
          tenant,
          // The core events uses this to build app_version
          //   Field(
          //     "", expr="_.split(' ', 1)[0] if _ else ''", coalesce=["device_app_version", "$app_version_string"]
          //   )
          // So we need to send $app_version_string which will be fed into app_version on amplitude
          // See ref: https://github.com/hopper-org/power-loader/blob/206e8e41259d5e01d122d9debb66b87da585a481/eventschema/core_traits.py#L75
          $app_version_string: version,
          preferred_languages: (navigator?.languages ?? []).join(","),
          referrer_url: document.referrer,
          referrer_url_host: document.referrer
            ? new URL(document.referrer).hostname
            : "",
        };
        if (additionalTrackingProperties) {
          properties = {
            ...properties,
            ...additionalTrackingProperties,
          };
        }
        if (trackingProperties) {
          properties = addTrackingProperties(trackingProperties, properties);
        }

        if (userSource) {
          properties[USER_SOURCE_KEY] = userSource;
          properties["utm_source"] = userSource;
        }
        if (userMedium) {
          properties[USER_MEDIUM_KEY] = userMedium;
          properties["utm_medium"] = userMedium;
        }

        if (userCampaign) {
          properties[USER_CAMPAIGN_KEY] = userCampaign;
          properties["utm_campaign"] = userCampaign;
        }

        if (userContent) {
          properties[USER_CONTENT_KEY] = userContent;
          properties["utm_content"] = userContent;
        }

        // Silence some console errors when properties are readonly for some reason
        if (!isReadonly(req.data, "properties")) {
          req.data.properties = properties;
        }
      }

      req.headers = { ...req.headers, ...headers };

      if (req.url !== analyticsEventApi && logRequest) {
        logRequest(req);
      }
      return req;
    },
    [
      requestHeaders,
      delegatedTo,
      tenant,
      locale,
      currency,
      trackingProperties,
      userSource,
    ]
  );

  const handleRes = useCallback((res: AxiosResponse) => {
    if (res.config.url !== analyticsEventApi && logResponse) {
      logResponse(res);
    }
    validateAndSetDeviceId(res.headers);
    return res;
  }, []);

  const handleError = useCallback(
    async (error: AxiosError) => {
      logError?.(error);

      // Don't handle tracking events errors to prevent ad block issues
      if (
        !error.request?.responseURL.includes("tracking/event") &&
        error.response?.status === 401
      ) {
        if (errorAction) {
          return errorAction(error);
        }
        navigate(PORTAL_UNAUTHORIZED_PATH, {
          state: {
            from: window.location.href,
            status: HttpStatusCode.Unauthorized,
          },
        });
      }

      // Handle retries
      const { config } = error;
      if (!config || !retryTransientErrors) {
        return Promise.reject(error);
      }
      const currentState = setCurrentState(config, {
        retries: 3,
        retryDelay: exponentialDelay,
        onRetry: () => {
          logger() && logger().info("Retrying request");
        },
        onMaxRetryTimesExceeded: () => {
          logger() && logger().error("Max retry reached");
        },
      });
      if (error.response && currentState.validateResponse?.(error.response)) {
        // no issue with response
        return error.response;
      }
      if (await shouldRetry(currentState, error)) {
        return handleRetry(axiosInstance, currentState, error, config);
      }

      await handleMaxRetryTimesExceeded(currentState, error);

      return Promise.reject(error);
    },
    [PORTAL_UNAUTHORIZED_PATH]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq),
      global: axios.interceptors.request.use(handleReq),
    };

    const responseInterceptor = {
      instance: axiosInstance.interceptors.response.use(handleRes, handleError),
      global: axios.interceptors.response.use(handleRes, handleError),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axiosInstance.interceptors.response.eject(responseInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
      axios.interceptors.response.eject(responseInterceptor.global);
    };
  }, [delegatedTo, requestHeaders, tenant, trackingProperties, userSource]);

  return <>{children}</>;
};
