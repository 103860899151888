import {
  AlertKeyEnum,
  FlightKey,
  WatchAlertViews,
} from "@b2bportal/air-price-watch-api";
import {
  FareSliceOutbound,
  FiatPrice,
  Flights,
  IsEligible,
  Prediction,
  ShopFilter,
  SliceOutbound,
  TripSegment,
  TripSummary,
} from "@b2bportal/air-shopping-api";
import {
  AirEntryProperties,
  AirlineCode,
  CallState,
  CfarFetchOfferRequest,
  Currency,
  Dealness,
  DisruptionFetchOfferRequest,
  FareDetails,
  FilteredFare,
  FlightRatingsEnum,
  FlightShopDefaultSort,
  FlightShopType,
  getHopperFareRatingName,
  ISelectedTrip,
  MultiTicketTypeEnum,
  PassengerTypes,
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  RegionType,
  RewardsPrice,
  SelectedSliceProperties,
  SliceStopCountFilter,
  TripCategory,
  TripDetails,
  ViewedForecastProperties,
  ViewedPriceFreezeProperties,
  ViewedTripSummaryProperties,
} from "@hopper-b2b/types";
import { IPriceFreezeOfferEntries } from "@hopper-b2b/ui";
import {
  formatInterval,
  getPlusDays,
  removeTimezone,
} from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import { isEqual, uniq } from "lodash-es";
import { createSelector } from "reselect";
import { IStoreState } from "../../../../reducers/types";
import { getCfarTotal, getSelectedCfarOffer } from "../../../cfar/reducer";
import {
  getDisruptionTotal,
  getSelectedScheduleChangeOffer,
} from "../../../disruption/reducer";
import { getReviewCardHeaderWithType } from "../../../freeze/reducer/selectors/textConstants";
import {
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
} from "../../../rewards/reducer";
import { IFilterState, initialFilterOptions } from "../../../search/reducer";
import {
  getAirlineFilter,
  getAirportFilter,
  getDepartureDate,
  getDestination,
  getFareclassOptionFilter,
  getFlightNumberFilter,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetArrivalTimeRange,
  getHasSetDepartureTimeRange,
  getHasSetFareClassFilter,
  getHasSetFlightNumberFilter,
  getHasSetStopsOption,
  getInfantsOnLapCount,
  getMaxPriceFilter,
  getOrigin,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getPassengersTotal,
  getReturnArrivalTimeRange,
  getReturnDate,
  getReturnDepartureTimeRange,
  getStopsOption,
  getStopsOptionFilter,
  getTripCategory,
  passengerCountSelector,
} from "../../../search/reducer/selectors";

import { IFlightListData } from "../../v3/components/FlightList/component";
import { getStopsString } from "../../v3/components/FlightList/components/FlightListInfo/textConstants";
import {
  FlightShopStep,
  IReturnSlicesByOutgoingId,
  ITripDetailsByTripId,
  ITripIdsByReturnSlice,
  ITripSummariesById,
  SortOption,
} from "../types";
import * as filters from "../utils/processFilters";
import * as sorters from "../utils/processSort";
import {
  flightShopProgressSelector,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
} from "./flightShopProgress";
import { getChfarTotal } from "../../../chfar/reducer";

// State selectors

export const getWatches = (state: IStoreState) => state.flightShop.watches;

export const tripSummariesLoadingSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripSummariesLoading;

export const predictionLoadingSelector = (state: IStoreState): boolean | null =>
  state.flightShop.predictionLoading;

export const tripSummariesErrorSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripSummariesError;

export const allTripSummariesSelector = (
  state: IStoreState
): ITripSummariesById => state.flightShop.tripSummariesById;

export const refreshPredictionSelector = (state: IStoreState) =>
  state.flightShop.rerunPrediction;

export const predictionSelector = (state: IStoreState): Prediction | null =>
  state.flightShop.prediction;

export const predictionErrorSelector = (state: IStoreState): boolean =>
  state.flightShop.predictionError;

export const selectedTripSelector = (state: IStoreState): ISelectedTrip =>
  state.flightShop.selectedTrip;

export const returnFlightsByOutgoingIdSelector = (
  state: IStoreState
): IReturnSlicesByOutgoingId => state.flightShop.returnFlightsByOutgoingId;

export const flightsSelector = (state: IStoreState): Flights | null =>
  state.flightShop.flights;

export const getPriceFreezeOfferWithSuggested = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer;

export const flightShopTypeSelector = (state: IStoreState) =>
  state.flightShop.flightShopType;

export const similarFlightsResponseSelector = (state: IStoreState) =>
  state.flightShop.similarFlightsResponse;

export const fetchSimilarFlightsCallStateSelector = (state: IStoreState) =>
  state.flightShop.fetchSimilarFlightsCallState;

export const transferToSimilarFlightsResponseSelector = (state: IStoreState) =>
  state.flightShop.transferToSimilarFlightsResponse;

export const fetchTransferToSimilarFlightsCallStateSelector = (
  state: IStoreState
) => state.flightShop.fetchTransferToSimilarFlightsCallState;

export const getChfarId = (state: IStoreState) => state.flightShop.chfarId;

export const getFlightShopDefaultSort = (state: IStoreState) =>
  state.flightShop.defaultSort;

export const isFlightShopLoadingTripSummaries = createSelector(
  tripSummariesLoadingSelector,
  fetchSimilarFlightsCallStateSelector,
  flightShopTypeSelector,
  (
    tripSummariesLoading,
    fetchSimilarFlightsCallState,
    flightShopType
  ): boolean => {
    switch (flightShopType) {
      case FlightShopType.PRICE_FREEZE_EXERCISE:
        return fetchSimilarFlightsCallState === CallState.InProcess;
      case FlightShopType.PRICE_FREEZE_PURCHASE:
      case FlightShopType.DEFAULT:
      default:
        return tripSummariesLoading ?? tripSummariesLoading === null;
    }
  }
);

export const priceDropProtectionCandidateIdSelector = createSelector(
  predictionSelector,
  (prediction) => {
    const priceDropProtection = prediction?.priceDropProtection;
    switch (priceDropProtection?.PriceDropProtection) {
      case PriceDropProtectionEnum.IsEligible:
        return priceDropProtection.candidateId;
      default:
        return undefined;
    }
  }
);

export const getPriceDropProperties = createSelector(
  predictionSelector,
  (prediction): Omit<PriceDropViewedProperties, "page"> => {
    const eligible =
      prediction?.priceDropProtection?.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;
    return {
      price_drop_offer_duration:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).monitoringDuration
              .inSeconds
          : 0,
      price_drop_offer_max_cap:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).maximumRefund.amount
              .amount
          : 0,
      price_drop_offer_min_cap:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).minimumRefund.amount
              .amount
          : 0,
    };
  }
);

export const isPriceFreezeOfferIncludedInShopSummarySelector = createSelector(
  getPriceFreezeOfferWithSuggested,
  (priceFreezeOffer) => !!priceFreezeOffer
);

export const isSelectReturnReadySelector = createSelector(
  selectedTripSelector,
  // getTripCategory,
  (selectedTrip /* tripCategory */): boolean => {
    const isDepartureFlightSelected =
      !!selectedTrip.outgoingFareId && !!selectedTrip.outgoingSliceId;
    return isDepartureFlightSelected /* && tripCategory === TripCategory.ROUND_TRIP */;
  }
);

export const isFlightReviewReadySelector = createSelector(
  selectedTripSelector,
  getTripCategory,
  (selectedTrip, tripCategory): boolean => {
    const isDepartureFlightSelected =
      !!selectedTrip.outgoingFareId && !!selectedTrip.outgoingSliceId;
    const isReturnFlightSelected =
      !!selectedTrip.returnFareId && !!selectedTrip.returnSliceId;

    return (
      isDepartureFlightSelected &&
      (tripCategory === TripCategory.ONE_WAY || isReturnFlightSelected)
    );
  }
);

export const watchesSelector = (state: IStoreState): WatchAlertViews =>
  state.flightShop.watches;

export const getCalendarBuckets = (state: IStoreState) =>
  state.flightShop.calendarBuckets;

export const getPriceRangeLegends = (state: IStoreState) =>
  state.flightShop.priceBuckets;

export const openCalendarModalSelector = (state: IStoreState): boolean =>
  state.flightShop.openCalendarModal;

export const openLocationModalSelector = (state: IStoreState): boolean =>
  state.flightShop.openLocationModal;

export const createWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.createWatchCallState;

export const listWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.listWatchCallState;

export const updateWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.updateWatchCallState;

export const deleteWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.deleteWatchCallState;

export const tripIdsByReturnSliceSelector = createSelector(
  selectedTripSelector,
  returnFlightsByOutgoingIdSelector,
  (
    selectedTrip,
    returnFlightsByOutgoingIdMap
  ): ITripIdsByReturnSlice | null => {
    if (selectedTrip && selectedTrip.outgoingSliceId) {
      return returnFlightsByOutgoingIdMap[selectedTrip.outgoingSliceId];
    } else {
      return null;
    }
  }
);

export const tripSummariesByIdSelector = createSelector(
  selectedTripSelector,
  isInChooseReturnStepSelector,
  allTripSummariesSelector,
  (selectedTrip, isInChooseReturnStep, tripSummaries): ITripSummariesById => {
    // return valid return slices for a selected outgoing slice
    if (isInChooseReturnStep && selectedTrip.outgoingSliceId) {
      const returnTrips = {};
      const outgoingSliceId = selectedTrip.outgoingSliceId;
      const outgoingFareRating = selectedTrip.outgoingFareRating;
      Object.values(tripSummaries).forEach((summary) => {
        const hasValidFares = summary.tripFares.some(
          (f) => f.fareShelf?.outgoing?.rating === outgoingFareRating
        );
        if (
          summary.outgoingSlice.id === outgoingSliceId &&
          (!outgoingFareRating || hasValidFares)
        ) {
          returnTrips[summary.tripId] = summary;
        }
      });
      return returnTrips;
    } else {
      // de-dupe trip summaries by unique outgoing slices
      const outgoingIds = new Set();
      const outgoingTrips = {};
      Object.values(tripSummaries).forEach((summary) => {
        if (!outgoingIds.has(summary.outgoingSlice.id)) {
          outgoingIds.add(summary.outgoingSlice.id);
          outgoingTrips[summary.tripId] = summary;
        }
      });
      return outgoingTrips;
    }
  }
);

export const tripSliceKeySelector = createSelector(
  isInChooseReturnStepSelector,
  (isInChooseReturnStep): string =>
    isInChooseReturnStep ? "returningSlice" : "outgoingSlice"
);

const tripSummariesSelector = createSelector(
  tripSummariesByIdSelector,
  (tripSummaries): TripSummary[] => Object.values(tripSummaries)
);

export const flightInfoSelector = (
  state: IStoreState,
  tripId: string
): TripSummary => state.flightShop.tripSummariesById[tripId];

export const flightFareDetailsSelector = (
  state: IStoreState,
  tripId: string
): TripSummary["tripFares"] =>
  state.flightShop.tripSummariesById[tripId]
    ? state.flightShop.tripSummariesById[tripId].tripFares
    : [];

export const tripDetailsLoadingSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripDetailsLoading;

export const tripDetailsByIdSelector = (
  state: IStoreState
): ITripDetailsByTripId => {
  return state.flightShop.tripDetailsById;
};

export const tripDetailsSelector = (
  state: IStoreState,
  tripId: string
): TripDetails => {
  return state.flightShop.tripDetailsById[tripId];
};

export const shopTrackingPropertiesSelector = (state: IStoreState) =>
  state.flightShop.trackingProperties;

export const tripSummarySelector = (
  state: IStoreState,
  tripId: string
): TripSummary => {
  // TODO: Do not fallback here... This is to improve mock data experience.
  return (
    state.flightShop.tripSummariesById[tripId] ||
    Object.values(state.flightShop.tripSummariesById)[0]
  );
};

export const airportsSelector = (state: IStoreState, tripId: string) => {
  const tripSummaries = tripSummariesByIdSelector(state);
  const flights = flightsSelector(state);
  const tripSummary = tripSummaries[tripId];
  return tripSummary
    ? tripSummary.context.airports
    : flights
    ? flights.airports
    : {};
};

export const tripTotalIncludingOffersSelector = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const selectedFareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );
  const disruptionTotal = getDisruptionTotal(state);
  const cfarTotal = getCfarTotal(state);
  const chfarTotal = getChfarTotal(state);
  const pricing = fareDetails?.paxPricings[0].pricing;
  const totalCostFiat = {
    value: pricing.total.fiat.value,
    currencyCode: pricing.total.fiat.currencyCode,
    currencySymbol: pricing.total.fiat.currencySymbol,
  };

  return {
    value: totalCostFiat.value + disruptionTotal + cfarTotal + chfarTotal,
    currencyCode: totalCostFiat.currencyCode,
    currencySymbol: totalCostFiat.currencySymbol,
  };
};

export const airlinesSelector = (state: IStoreState, tripId: string) => {
  const tripSummaries = tripSummariesByIdSelector(state);
  const flights = flightsSelector(state);
  const tripSummary = tripSummaries[tripId];
  return tripSummary
    ? tripSummary.context.airlines
    : flights
    ? flights.airlines
    : {};
};

export const selectedSliceFareDetailsSelector = (
  state: IStoreState,
  tripId: string,
  fareId: string
): FareDetails | undefined =>
  state.flightShop.tripDetailsById[tripId].fareDetails.find(
    (fare) => fare.id === fareId
  );

export const sortOptionSelector = (state: IStoreState): SortOption =>
  state.flightShop.sortOption;

// Max Price Filter
// Note: these values are the same regardless of our progress
export const maxFlightPriceSelector = createSelector(
  allTripSummariesSelector,
  (flights): number => {
    let max = 0;
    Object.values(flights).forEach((flight) => {
      flight.tripFares?.forEach((fare) => {
        max = Math.max(fare.amount.fiat?.value, max);
      });
    });
    return max;
  }
);
export const minFlightPriceSelector = createSelector(
  allTripSummariesSelector,
  (flights): number => {
    let min = Number.MAX_SAFE_INTEGER;
    Object.values(flights).forEach((flight) => {
      flight.tripFares?.forEach((fare) => {
        min = Math.min(fare.amount.fiat?.value, min);
      });
    });
    return min;
  }
);

export const hasSetMaxPriceFilterSelector = createSelector(
  getMaxPriceFilter,
  maxFlightPriceSelector,
  (maxPriceFilter, maxFlightPrice) => {
    return (
      maxPriceFilter !== initialFilterOptions.maxPriceFilter &&
      maxPriceFilter !== maxFlightPrice
    );
  }
);

// Airline Filter
export interface IAirlineOptions {
  label: AirlineCode;
  value: AirlineCode;
}

export const allAirlinesSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey): IAirlineOptions[] => {
    // TODO: switch to airline name + airline code when it is available from the backend
    const airlines = new Set<AirlineCode>();
    const allAirlines: IAirlineOptions[] = [];

    tripSummaries.forEach((flight: TripSummary) =>
      flight[tripSliceKey]!.segmentDetails.forEach((segment: TripSegment) => {
        if (!airlines.has(segment.airlineCode)) {
          allAirlines.push({
            value: segment.airlineCode,
            label: segment.airlineName,
          });
        }
        airlines.add(segment.airlineCode);
      })
    );

    return allAirlines;
  }
);

// Airport Filter
export interface IAirportOptions {
  label: string;
  value: string;
}

export const allAirportsSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey): IAirportOptions[] => {
    const airports = new Set<string>();
    const allAirports: IAirportOptions[] = [];

    tripSummaries.forEach((flight) => {
      const airportCode = flight[tripSliceKey].originCode;
      const airportName = flight[tripSliceKey].originName;
      if (!airports.has(airportCode)) {
        allAirports.push({ value: airportCode, label: airportName });
      }
      airports.add(airportCode);
    });

    return allAirports;
  }
);

export interface IFlightNumbersByAirlineCode {
  [key: string]: string[];
}

// Flight Number Selector
export const flightNumbersByAirlineSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey) => {
    const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};

    tripSummaries.forEach((flight) => {
      const [firstSegment] = flight[tripSliceKey].segmentDetails;
      const { flightNumber } = firstSegment;
      const airlineCode = firstSegment.airlineCode;

      if (!flightNumbersByAirline[airlineCode]) {
        flightNumbersByAirline[airlineCode] = [flightNumber];
      } else {
        flightNumbersByAirline[airlineCode] = uniq([
          ...flightNumbersByAirline[airlineCode],
          flightNumber,
        ]);
      }
    });

    return flightNumbersByAirline;
  }
);

const allFiltersSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOption,
  getMaxPriceFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  (
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter
  ): IFilterState => ({
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
  })
);

export const hasSetNonFareclassFiltersSelector = createSelector(
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

const filteredFlightsSelector = createSelector(
  // Search Filter Selectors
  allFiltersSelector,

  // Search Filter Applied
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,

  // Shop Selectors
  selectedTripSelector,
  tripSummariesByIdSelector,
  isInChooseReturnStepSelector,
  (
    allFiltersSelector: IFilterState,
    hasSetStopsOption,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    hasSetMaxPriceFilter,
    hasSetFareClassFilter,
    selectedTrip,
    tripSummariesById: ITripSummariesById,
    isInChooseReturnStep: boolean
  ): ITripSummariesById => {
    const {
      stopsOption,
      maxPriceFilter,
      outboundDepartureTimeRange,
      outboundArrivalTimeRange,
      returnDepartureTimeRange,
      returnArrivalTimeRange,
      airlineFilter,
      airportFilter,
      flightNumberFilter,
      fareclassOptionFilter,
    } = allFiltersSelector;

    const isReturn = isInChooseReturnStep;
    const tripSliceKey = isReturn ? "returningSlice" : "outgoingSlice";

    const selectedFaresOptionsArray = Object.keys(fareclassOptionFilter).filter(
      (key) => fareclassOptionFilter[key] && key
    );

    const meetsFilterPredicates = (tripSummary: TripSummary) => {
      let meetsConditions = true;

      if (hasSetStopsOption) {
        meetsConditions =
          meetsConditions &&
          filters.performStopOptionFilter(
            tripSummary[tripSliceKey]!,
            stopsOption
          );
      }

      if (hasSetDepartureTimeRange && !isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilter(
            tripSummary[tripSliceKey]!,
            outboundDepartureTimeRange,
            outboundArrivalTimeRange
          );
      }

      if (hasSetArrivalTimeRange && isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilter(
            tripSummary[tripSliceKey]!,
            returnDepartureTimeRange,
            returnArrivalTimeRange
          );
      }

      if (hasSetAirlineFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirlineFilter(
            tripSummary[tripSliceKey]!,
            airlineFilter
          );
      }

      if (hasSetAirportFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirportFilter(
            tripSummary[tripSliceKey]!,
            airportFilter
          );
      }

      if (hasSetFlightNumberFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performFlightNumberFilter(
            tripSummary[tripSliceKey]!,
            flightNumberFilter
          );
      }

      if (hasSetMaxPriceFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performMaxPriceFilter(tripSummary, maxPriceFilter);
      }

      if (hasSetFareClassFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performFareClassFilter(
            tripSummary,
            selectedFaresOptionsArray,
            isReturn,
            selectedTrip.outgoingFareRating ?? undefined
          );
      }

      return meetsConditions;
    };

    const filteredTrips = {};
    Object.values(tripSummariesById).forEach((summary) => {
      if (meetsFilterPredicates(summary)) {
        filteredTrips[summary.tripId] = summary;
      }
    });
    return filteredTrips;
  }
);

export const orderedAndFilteredFlightIdsSelector = createSelector(
  filteredFlightsSelector,
  sortOptionSelector,
  tripSliceKeySelector,
  (filteredFlights, sortOption, tripSliceKey): string[] => {
    const tripSummaries = Object.values(filteredFlights);
    switch (sortOption) {
      case "fareScore":
        return sorters.orderByRecommended(tripSummaries);
      case "price":
        return sorters.orderByPrice(tripSummaries);
      case "departureTime":
        return sorters.orderByDepartureTime(tripSummaries, tripSliceKey);
      case "arrivalTime":
        return sorters.orderByArrivalTime(tripSummaries, tripSliceKey);
      case "stops":
        return sorters.orderByStops(tripSummaries, tripSliceKey);
      case "duration":
        return sorters.orderByDuration(tripSummaries, tripSliceKey);

      default:
        return sorters.orderByRecommended(tripSummaries);
    }
  }
);

export const shopPricingInfoSelector = (state: IStoreState) => {
  const selectedTrip = state.flightShop.selectedTrip;
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  if (!tripDetails) {
    return {
      fare: [],
    };
  }
  const fare = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const fareDetails = tripDetails.fareDetails.find((f) => f.id === fare);
  return {
    fare: fareDetails?.paxPricings,
  };
};

export const isOutgoingMultiTicket = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    if (trip && selectedTrip.outgoingFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedTrip.outgoingFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const isReturnMultiTicket = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    if (trip && selectedTrip.returnFareId) {
      return !!trip.fareDetails.find((f) => f.id === selectedTrip.returnFareId)
        ?.multiTicket;
    }
    return false;
  }
);

export const returnFareSelector = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): FareDetails | null => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    if (trip && selectedTrip.returnFareId) {
      return trip.fareDetails.find((f) => f.id === selectedTrip.returnFareId);
    }
    return null;
  }
);

export const isReturnFareHackerFareSelector = createSelector(
  returnFareSelector,
  (returnFare) => {
    return returnFare?.multiTicketType === MultiTicketTypeEnum.HackerFare;
  }
);

export const shopFilterSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOptionFilter,
  (fareclassOptionFilter, stopsOption): ShopFilter => {
    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    return tripFilter;
  }
);

export const fetchCfarOfferRequestParameters = createSelector(
  shopFilterSelector,
  selectedTripSelector,
  passengerCountSelector,
  (shopFilter, selectedTrip, passengerCount): CfarFetchOfferRequest | null => {
    const tripId = selectedTrip.tripId;
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    if (tripId && fareId) {
      return {
        tripId,
        fareId,
        tripFilter: shopFilter,
        passengers: { ...passengerCount } as { [key in string]: number },
      };
    } else {
      return null;
    }
  }
);

export const numberOfPassengersSelector = createSelector(
  passengerCountSelector,
  (passengerCount) => {
    return Object.values(passengerCount).reduce((a, b) => a + b, 0);
  }
);

export const fetchDisruptionOfferRequestParameters = createSelector(
  selectedTripSelector,
  passengerCountSelector,
  (selectedTrip, passengerCount): DisruptionFetchOfferRequest | null => {
    const tripId = selectedTrip.tripId;
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    // Omit lap infants
    delete passengerCount[PassengerTypes.InfantInLap];

    if (tripId && fareId) {
      return {
        tripId,
        fareId,
        numberOfPassengers: Object.values(passengerCount).reduce(
          (a, b) => a + b,
          0
        ),
      };
    } else {
      return null;
    }
  }
);

export const alertKeySelector = createSelector(
  shopFilterSelector,
  tripSummariesSelector,
  flightsSelector,
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  (
    shopFilter,
    tripSummaries,
    flights,
    departureDate,
    returnDate,
    origin,
    destination
  ): FlightKey | null => {
    if (tripSummaries.length < 1 && !flights) {
      return null;
    }

    const summary = {
      originCode: "",
      destinationCode: "",
      departureTime: "",
      returningDepartureTime: "",
    };

    // Flight Shop v0
    if (tripSummaries[0]) {
      const tempSummary = tripSummaries[0];
      summary.originCode = tempSummary.outgoingSlice.originCode;
      summary.destinationCode = tempSummary.outgoingSlice.destinationCode;
      summary.departureTime = departureDate
        ? dayjs(departureDate).format("YYYY-MM-DD")
        : dayjs(tempSummary.outgoingSlice.departureTime).format("YYYY-MM-DD");
      if (returnDate) {
        summary.returningDepartureTime =
          dayjs(returnDate).format("YYYY-MM-DD") ||
          (tempSummary.returningSlice
            ? dayjs(tempSummary.returningSlice.departureTime).format(
                "YYYY-MM-DD"
              )
            : "");
      }
    } else if (flights && Object.values(flights?.trips)[0]) {
      //Flight shop v2
      const flight = Object.values(flights?.trips)[0];

      const departureSlice = flights.slices[flight.outbound];
      const returnSlice = flight.return ? flights.slices[flight.return] : null;
      summary.originCode = departureSlice.origin;
      summary.destinationCode = departureSlice.destination;
      summary.departureTime = departureDate
        ? dayjs(departureDate).format("YYYY-MM-DD")
        : dayjs(departureSlice.departure).format("YYYY-MM-DD");
      if (returnDate) {
        summary.returningDepartureTime =
          dayjs(returnDate).format("YYYY-MM-DD") ||
          (returnSlice?.departure
            ? dayjs(returnSlice.departure).format("YYYY-MM-DD")
            : "");
      }
    }

    return {
      AlertKey: AlertKeyEnum.FlightKey,
      value: {
        filter: shopFilter,
        origin: origin
          ? origin.id.code
          : {
              code: summary.originCode,
              regionType: RegionType.Airport,
            },
        destination: destination
          ? destination.id.code
          : {
              code: summary.destinationCode,
              regionType: RegionType.Airport,
            },
        departureDate: departureDate
          ? dayjs(departureDate).format("YYYY-MM-DD")
          : dayjs(summary.departureTime).format("YYYY-MM-DD"),
        ...(returnDate && {
          returnDate:
            dayjs(returnDate).format("YYYY-MM-DD") ||
            dayjs(summary.returningDepartureTime).format("YYYY-MM-DD"),
        }),
      },
    };
  }
);

export const isWatchingSelector = createSelector(
  alertKeySelector,
  watchesSelector,
  (alertKey, watches) => {
    return (
      watches &&
      watches.watches.length > 0 &&
      !!watches.watches.find((watch) => isEqual(watch.key, alertKey))
    );
  }
);

export const selectedTripDetailsSelector = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetails, selectedTrip): TripDetails | null => {
    if (selectedTrip?.tripId) {
      return tripDetails[selectedTrip.tripId];
    } else {
      return null;
    }
  }
);

export const getAirEntryProperties = createSelector(
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getPassengersTotal,
  getIsFirstLaunch,
  getWatches,
  selectedTripDetailsSelector,
  getRewardsAccounts,
  getTripCategory,
  (
    origin,
    destination,
    departureDate,
    returnDate,
    passengerCount,
    isFirstLaunch,
    watches,
    tripDetails,
    rewardsAccounts,
    tripCategory
  ): AirEntryProperties => ({
    first_launch: isFirstLaunch,
    origin: !origin
      ? tripDetails
        ? tripDetails.slices[0].originName
        : ""
      : `${origin.id.code.regionType}/${origin.id.code.code}`,
    destination: !destination
      ? tripDetails
        ? tripDetails.slices[0].destinationName
        : ""
      : `${destination.id.code.regionType}/${destination.id.code.code}`,
    origin_country_code:
      origin?.id.code.code ||
      (tripDetails ? tripDetails.slices[0].originCode : ""),
    destination_country_code:
      destination?.id.code.code ||
      (tripDetails ? tripDetails.slices[0].destinationCode : ""),
    departure_date: !departureDate
      ? tripDetails
        ? tripDetails.slices[0].departureTime
        : ""
      : dayjs(departureDate).format("YYYY-MM-DD"),
    ...(tripDetails &&
      tripDetails.slices.length > 1 && {
        return_date: dayjs(tripDetails.slices[1].departureTime).format(
          "YYYY-MM-DD"
        ),
      }),
    ...(returnDate && {
      return_date: dayjs(returnDate).format("YYYY-MM-DD"),
    }),
    number_of_active_watches: watches.length,
    pax_total: passengerCount,
    trip_type: tripCategory,
    rewards_accounts: rewardsAccounts
      .map((r) => r.productDisplayName)
      .join(","),
    advanced: dayjs(departureDate).diff(dayjs(), "days"),
    los:
      returnDate || (tripDetails && tripDetails.slices.length > 0)
        ? dayjs(returnDate).diff(departureDate, "days")
        : 1,
  })
);

export const getViewedForecastProperties = createSelector(
  predictionSelector,
  getAirEntryProperties,
  getSelectedAccount,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  orderedAndFilteredFlightIdsSelector,
  allTripSummariesSelector,
  (
    prediction,
    airEntryProps,
    account,
    isInChooseDepartureStep,
    isInChooseReturnStep,
    flights,
    tripSummariesById
  ): ViewedForecastProperties => {
    const numberOfFares = flights.reduce((fareCount: number, flight) => {
      return fareCount + tripSummariesById[flight].tripFares.length;
    }, 0);

    return {
      ...airEntryProps,
      flights_shown:
        isInChooseDepartureStep || isInChooseReturnStep
          ? flights.length
          : undefined,
      slice_direction: isInChooseReturnStep
        ? "return"
        : isInChooseDepartureStep
        ? "outbound"
        : undefined,
      fares_shown_outbound: isInChooseDepartureStep ? numberOfFares : undefined,
      fares_shown_return: isInChooseReturnStep ? numberOfFares : undefined,

      account_type_selected: account?.accountDisplayName || "",
      dealness: prediction?.dealness || "",
      recommendation: !prediction
        ? "null"
        : prediction.dealness === Dealness.Wait
        ? "wait"
        : "buy",
    };
  }
);

export const getSelectedOutgoingSliceProperties = createSelector(
  getViewedForecastProperties,
  selectedTripSelector,
  (viewedForecastProperties, selectedTrip): SelectedSliceProperties => {
    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedTrip.outgoingFareRating),
    };
  }
);

export const getSelectedReturnSliceProperties = createSelector(
  getViewedForecastProperties,
  selectedTripSelector,
  (viewedForecastProperties, selectedTrip): SelectedSliceProperties | null => {
    if (!viewedForecastProperties) {
      return null;
    }

    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedTrip.returnFareRating),
    };
  }
);

export const getTripDetails = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";

  return tripDetailsSelector(state, tripId);
};

export const getShopTrackingProperties = (state: IStoreState) =>
  shopTrackingPropertiesSelector(state);

export const getViewedTripSummaryProperties = createSelector(
  getSelectedOutgoingSliceProperties,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  selectedTripSelector,
  getTripDetails,
  getShopTrackingProperties,
  (
    viewedSliceProperties,
    outgoingHackerFare,
    returnHackerFare,
    selectedTrip,
    tripDetails,
    shopTrackingProperties
  ): ViewedTripSummaryProperties => {
    const selectedFareId = selectedTrip?.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    const fareDetails = tripDetails?.fareDetails.find(
      (f) => f.id === selectedFareId
    );

    return {
      ...viewedSliceProperties,
      ...(shopTrackingProperties?.properties || {}),
      multi_ticket_type: fareDetails?.multiTicketType,
      includes_multi_ticket: outgoingHackerFare || returnHackerFare,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
    };
  }
);

export const getPriceFreezeOfferCheapestTripTripId = (
  state: IStoreState
): string => state.flightShop.priceFreezeOffer?.cheapestTrip.tripId || "";

export const getDefaultPriceFreezeOffer = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer?.offer;

export const getPriceFreezeOfferCap = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer?.offer.cap;

export const getPriceFreezeOfferFiat = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer?.offer.perPaxAmount.fiat;

export const getPriceFreezeOfferRewards = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer?.offer.perPaxAmount.rewards;

export const getPriceFreezeOfferCheapestFare = (state: IStoreState): string =>
  state.flightShop.priceFreezeOffer?.cheapestTrip.fareId || "";

export const getPriceFreezeOfferDuration = createSelector(
  getPriceFreezeOfferWithSuggested,
  (offer) => {
    return offer?.offer.timeToLive;
  }
);

export const getPriceFreezeCheapestFrozenPrice = createSelector(
  getPriceFreezeOfferWithSuggested,
  tripDetailsByIdSelector,
  getSelectedAccount,
  (
    priceFreezeOffer,
    tripDetailsById,
    account
  ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | undefined => {
    if (!priceFreezeOffer) {
      return undefined;
    }

    const { cheapestTrip } = priceFreezeOffer;
    const tripDetails = tripDetailsById[cheapestTrip.tripId];

    if (!tripDetails) {
      return undefined;
    }

    const fareDetails = tripDetails.fareDetails.find(
      (f) => f.id === cheapestTrip.fareId
    )?.paxPricings[0].pricing.total;

    if (!fareDetails) {
      return undefined;
    }

    return {
      fiat: fareDetails.fiat,
      rewards: account
        ? fareDetails.rewards[account?.accountReferenceId]
        : undefined,
    };
  }
);

export const getViewedPriceFreezeProperties = createSelector(
  getPriceFreezeOfferWithSuggested,
  getPriceFreezeCheapestFrozenPrice,
  (priceFreeze, cheapestFrozenPrice): ViewedPriceFreezeProperties | null => {
    if (!priceFreeze || !cheapestFrozenPrice) {
      return null;
    }

    return {
      price_freeze_shown: Boolean(priceFreeze?.offer.id),
      price_freeze_duration: priceFreeze?.offer.timeToLive?.inSeconds || 0,
      price_freeze_otm_cap_usd: priceFreeze?.offer.cap.value.amount || 0,
      price_freeze_cost_per_pax:
        priceFreeze?.offer.perPaxAmount.fiat.value || 0,
      current_lowest_price_usd: cheapestFrozenPrice?.fiat.value || 0,
      price_freeze_total_cost: priceFreeze?.offer.totalAmount.fiat.value || 0,
    };
  }
);

export const getPriceFreezeOffer = createSelector(
  getPriceFreezeOfferCheapestTripTripId,
  tripDetailsByIdSelector,
  getPriceFreezeOfferCheapestFare,
  getPriceFreezeOfferWithSuggested,
  tripSummariesByIdSelector,
  flightsSelector,
  (
    id: string,
    tripDetailsById,
    fareId,
    offerWithSuggested,
    tripSummaries,
    flights
  ): IPriceFreezeOfferEntries | null => {
    if (!offerWithSuggested) {
      return null;
    }

    const tripSummary = tripSummaries[id];
    // note: tripSummary is from v1, and flights is from v2
    const airports = tripSummary
      ? tripSummary.context.airports
      : flights
      ? flights.airports
      : {};

    const getSliceDetails = (isOutgoing: boolean, tripDetails: TripDetails) => {
      const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
      const { type, description } = getReviewCardHeaderWithType(
        isOutgoing,
        airports[slice!.destinationCode]
          ? airports[slice!.destinationCode].cityName
          : slice!.destinationName,
        removeTimezone(slice!.departureTime)
      );

      return {
        airlineCode: slice!.segmentDetails[0].airlineCode,
        airline: slice!.segmentDetails[0].airlineName,
        formattedDepartureDescription: description,
        formattedDepartureTime: dayjs(
          removeTimezone(slice!.departureTime)
        ).format("h:mm A"),
        formattedArrivalTime: dayjs(removeTimezone(slice!.arrivalTime)).format(
          "h:mm A"
        ),
        destinationCode: slice!.destinationCode,
        duration: formatInterval(slice!.totalDurationMinutes || 0),
        tripCategory: type,
        stopString: getStopsString(slice!.stops),
        plusDays: getPlusDays(slice!),
      };
    };

    const tripDetails: TripDetails | null = tripDetailsById[id] ?? null;
    if (tripDetails) {
      const fares = tripDetails?.fareDetails.find((f) => f.id === fareId);
      return {
        departureFlight: {
          ...getSliceDetails(true, tripDetails),
          fareClass: fares?.slices[0]?.fareShelf?.shortBrandName ?? "",
        },
        returnFlight: tripDetails.slices[1]
          ? {
              ...getSliceDetails(false, tripDetails),
              fareClass: fares?.slices[1]?.fareShelf?.shortBrandName ?? "",
            }
          : null,
      };
    }
    return {
      departureFlight: null,
      returnFlight: null,
    };
  }
);

export const getOutboundFlightList = createSelector(
  flightsSelector,
  (flights): IFlightListData[] => {
    return flights?.outbound || [];
  }
);

export const selectFareSliceById = (fareId: string) =>
  createSelector(
    flightsSelector,
    (flights: Flights) => flights.fareSlices[fareId]
  );

export const getReturnFlightList = createSelector(
  flightsSelector,
  selectedTripSelector,
  (flights, selectedTrip): IFlightListData[] => {
    if (!selectedTrip.outgoingSliceId || !flights) return [];

    const getReturnFlights = (returnTripIds: string[]) => {
      const JETBLUE_CODE = "B6";
      const isOutgoingJetBlue =
        selectedTrip.outgoingSliceId &&
        flights.slices[selectedTrip.outgoingSliceId].marketingAirline ===
          JETBLUE_CODE;
      // Flight can have a marketingAirline of B6 but can be a different operatingAirline (like AA) so this checks if it's truly B6 (JetBlue)
      const isOutgoingOperatedByJetBlue =
        isOutgoingJetBlue &&
        selectedTrip.outgoingSliceId &&
        flights.slices[selectedTrip.outgoingSliceId].segments.every(
          (segment) => segment.operatingAirline === JETBLUE_CODE
        );

      const selectedOutgoingFare = selectedTrip.outgoingFareId
        ? flights.fares[selectedTrip.outgoingFareId]
        : null;
      const selectedOutgoingFareSlice = selectedOutgoingFare
        ? flights.fareSlices[selectedOutgoingFare.outbound]
        : null;

      const selectedOutgoingFareSliceId = selectedOutgoingFareSlice?.id;

      return returnTripIds.reduce(
        (returnFlightList: IFlightListData[], tripId: string) => {
          const trip = flights.trips[tripId];
          const returnSliceId = flights.trips[tripId].return || "";

          const tripFares = trip.fares.map(
            (fareId: string) => flights.fares[fareId]
          );

          // Logic for filtering return flights
          const getFilteredFares = () => {
            // If outgoing isn't JetBlue, do nothing because filtering is only needed for JetBlue flights
            if (!isOutgoingJetBlue || !isOutgoingOperatedByJetBlue) {
              return tripFares.filter((tripFare) => {
                if (!tripFare.return) return true;

                const returnFareSlice = flights.fareSlices[tripFare.return];
                const outgoingFareSlice = flights.fareSlices[tripFare.outbound];

                // if the fare has the selected outgoing FARE SLICE, the return fare slice must be valid (even though the fare classes are different - Hacker fare)
                if (
                  selectedOutgoingFareSliceId &&
                  tripFare.outbound === selectedOutgoingFareSliceId
                ) {
                  return true;
                  // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                  // as long as the matching outbound fare is higher or equal to the selected outboound
                } else if (
                  selectedTrip.outgoingFareRating !== null &&
                  selectedTrip.outgoingFareRating !== undefined
                ) {
                  return (
                    returnFareSlice.fareShelf.value >=
                      selectedTrip.outgoingFareRating &&
                    outgoingFareSlice.fareShelf.value >=
                      selectedTrip.outgoingFareRating
                  );
                } else {
                  return false;
                }
              });
            }
            // Logic for filtering for JetBlue (B6 flight):
            return tripFares.filter((tripFare) => {
              if (!tripFare.return) return true;
              const returnFareSlice = flights.fareSlices[tripFare.return];
              const outgoingFareSlice = flights.fareSlices[tripFare.outbound];
              // Similar to outgoing, flight can have marketingAirline of B6 but can be a different operatingAirline (like AA) so this checks if it's truly B6 (JetBlue)
              const isReturnSliceOperatedByJetBlue = flights.slices[
                returnFareSlice.slice
              ].segments.every(
                (segment) => segment.operatingAirline === JETBLUE_CODE
              );
              const isReturnSliceMarketingAirlineJetBlue =
                flights.slices[returnFareSlice.slice].marketingAirline ===
                JETBLUE_CODE;

              // If the return flight is not operated by JetBlue and the marketing airline isn't JetBlue, return false
              if (
                !isReturnSliceOperatedByJetBlue ||
                !isReturnSliceMarketingAirlineJetBlue
              ) {
                return false;
              }
              // If outgoing is JetBlue Basic (fareRating = 0), only show JetBlue Basic return fares
              if (
                isReturnSliceMarketingAirlineJetBlue &&
                isReturnSliceOperatedByJetBlue &&
                selectedTrip.outgoingFareRating === 0
              ) {
                return returnFareSlice.fareShelf.value === 0;
              }

              // For jetblue, if fare is refundable filter out non-refundable fare
              if (
                returnFareSlice.fareShelf.value ===
                  selectedOutgoingFareSlice?.fareShelf.value &&
                selectedOutgoingFareSlice.fareBrandName.includes("Refundable")
              ) {
                return (
                  returnFareSlice.fareBrandName ===
                  selectedOutgoingFareSlice.fareBrandName
                );
              }

              if (
                selectedTrip.outgoingFareRating !== null &&
                selectedTrip.outgoingFareRating !== undefined
              ) {
                // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                // as long as the matching outbound fare is higher or equal to the selected outbound
                return (
                  returnFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating &&
                  outgoingFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating
                );
              } else {
                return false;
              }
            });
          };

          const filteredTripFares = getFilteredFares();

          if (filteredTripFares.length > 0) {
            returnFlightList.push({
              slice: returnSliceId,
              fares: filteredTripFares,
            });
          }

          return returnFlightList;
        },
        []
      );
    };

    const flight = flights.outbound.find(
      (flight: SliceOutbound) => flight.slice === selectedTrip.outgoingSliceId
    );

    //Fall back to the first fare next list if fare is not found.
    //This will happen if there was a refresh in flights object.
    //The fallback can be used as all fares next list are identical on the same outbound flight.
    const fare =
      !!flight &&
      (flight.fares.find(
        (fare: FareSliceOutbound) =>
          fare.example.fare === selectedTrip.outgoingFareId
      ) ??
        flight.fares[0]);
    return !!fare && fare.next ? getReturnFlights(fare.next) : [];
  }
);

export const getFlightList = createSelector(
  getOutboundFlightList,
  getReturnFlightList,
  isInChooseReturnStepSelector,
  (
    outgoingFlightList,
    returnFlightList,
    isInChooseReturnStep
  ): IFlightListData[] => {
    return isInChooseReturnStep ? returnFlightList : outgoingFlightList;
  }
);

export interface IShopFilterSelector {
  airlineOptions: IAirlineOptions[];
  airportOptions: IAirportOptions[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  priceMin: Omit<FiatPrice, "currencySymbol">;
  priceMax: Omit<FiatPrice, "currencySymbol">;
}

export const allFlightShopFilterSelector = createSelector(
  flightsSelector,
  getFlightList,
  (flights, flightList): IShopFilterSelector => {
    const airlines = new Set<AirlineCode>();
    const allAirlines: IAirlineOptions[] = [];
    const airports = new Set<string>();
    const allAirports: IAirportOptions[] = [];
    const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};

    const min = { value: Number.MAX_SAFE_INTEGER, currencyCode: Currency.USD };
    const max = { value: 0, currencyCode: Currency.USD };

    const flightShopFilters = {
      airlineOptions: allAirlines,
      airportOptions: allAirports,
      flightNumbersByAirline,
      priceMin: min,
      priceMax: max,
    };

    if (!flights) return flightShopFilters;

    flightList.forEach((flight: IFlightListData) => {
      const flightSlice = flights.slices[flight.slice];
      // AIRLINES
      const airlineCode = flightSlice!.marketingAirline;
      if (!airlines.has(airlineCode)) {
        allAirlines.push({
          value: airlineCode,
          label: flights?.airlines[airlineCode]?.displayName || "",
        });
      }
      airlines.add(airlineCode);

      //AIRPORTS
      const airportCode = flightSlice.origin;
      const airportName = flights.airports[flightSlice.origin].name;
      if (!airports.has(airportCode)) {
        allAirports.push({ value: airportCode, label: airportName });
      }
      airports.add(airportCode);

      //PRICES
      flight.fares?.forEach((fare: any) => {
        const fiatAmount = fare.amount.fiat;
        if (fiatAmount) {
          if (fiatAmount.currencyCode !== min.currencyCode) {
            Object.assign(min, fiatAmount);
          } else if (fiatAmount.value < min.value) {
            Object.assign(min, fiatAmount);
          }
          if (fiatAmount.currencyCode !== max.currencyCode) {
            Object.assign(max, fiatAmount);
          } else if (fiatAmount.value > max.value) {
            Object.assign(max, fiatAmount);
          }
        }
      });

      //FLIGHT NUMBER
      const [{ flightNumber }] = flightSlice.segments;

      if (!flightNumbersByAirline[airlineCode]) {
        flightNumbersByAirline[airlineCode] = [flightNumber];
      } else {
        flightNumbersByAirline[airlineCode] = uniq([
          ...flightNumbersByAirline[airlineCode],
          flightNumber,
        ]);
      }
    });

    return flightShopFilters;
  }
);

export const maxFlightPriceSelectorV2 = createSelector(
  getFlightList,
  getMaxPriceFilter,
  (flightList, maxPriceFilter): number => {
    // If the max is equal to the default value (MAX_SAFE_INTEGER)
    // then set it to zero for the purpose of finding the max. If there are no flights
    // then set the max back to the default value (MAX_SAFE_INTEGER)
    let max = maxPriceFilter === Number.MAX_SAFE_INTEGER ? 0 : maxPriceFilter;
    flightList.forEach((flight: IFlightListData) => {
      flight.fares?.forEach((fare: any) => {
        max = Math.max(fare.amount.fiat?.value, max);
      });
    });
    return max === 0 ? Number.MAX_SAFE_INTEGER : max;
  }
);

export const hasSetMaxPriceFilterSelectorV2 = createSelector(
  getMaxPriceFilter,
  maxFlightPriceSelectorV2,
  (maxPriceFilter, maxFlightPrice) => {
    return (
      maxPriceFilter !== initialFilterOptions.maxPriceFilter &&
      maxPriceFilter !== maxFlightPrice
    );
  }
);

export const hasSetNonFareclassFiltersSelectorV2 = createSelector(
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelectorV2,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

export const filteredFlightsSelectorV2 = createSelector(
  // Search Filter Selectors
  allFiltersSelector,

  // Search Filter Applied
  getHasSetStopsOption,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,

  // Shop Selectors
  isInChooseReturnStepSelector,
  getFlightList,
  flightsSelector,
  (
    allFiltersSelector: IFilterState,
    hasSetStopsOption,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    hasSetMaxPriceFilter,
    hasSetFareClassFilter,
    isInChooseReturnStep,
    flightList,
    flights
  ): IFlightListData[] => {
    const {
      stopsOption,
      maxPriceFilter,
      outboundDepartureTimeRange,
      outboundArrivalTimeRange,
      returnDepartureTimeRange,
      returnArrivalTimeRange,
      airlineFilter,
      airportFilter,
      flightNumberFilter,
      fareclassOptionFilter,
    } = allFiltersSelector;

    const isReturn = isInChooseReturnStep;

    const selectedFaresOptionsArray = Object.keys(fareclassOptionFilter).filter(
      (key) => fareclassOptionFilter[key] && key
    );

    const meetsFilterPredicates = (flight: IFlightListData) => {
      let meetsConditions = true;

      const flightSlice = flights!.slices[flight.slice];
      const flightFares = flight.fares;
      if (hasSetStopsOption) {
        meetsConditions =
          meetsConditions &&
          filters.performStopOptionFilterV2(flightSlice, stopsOption);
      }

      if ((hasSetDepartureTimeRange || hasSetArrivalTimeRange) && !isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilterV2(
            flightSlice,
            outboundDepartureTimeRange,
            outboundArrivalTimeRange
          );
      }

      if ((hasSetDepartureTimeRange || hasSetArrivalTimeRange) && isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilterV2(
            flightSlice,
            returnDepartureTimeRange,
            returnArrivalTimeRange
          );
      }

      if (hasSetAirlineFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirlineFilterV2(flightSlice, airlineFilter);
      }

      if (hasSetAirportFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirportFilterV2(flightSlice, airportFilter);
      }

      if (hasSetFlightNumberFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performFlightNumberFilterV2(flightSlice, flightNumberFilter);
      }

      if (hasSetMaxPriceFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performMaxPriceFilterV2(flightFares, maxPriceFilter);
      }

      if (hasSetFareClassFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performFareClassFilterV2(
            flights!,
            flight,
            selectedFaresOptionsArray
          );
      }

      return meetsConditions;
    };

    const filteredFlights: any = [];

    if (flights) {
      flightList.forEach((flight) => {
        if (meetsFilterPredicates(flight)) {
          filteredFlights.push(flight);
        }
      });
    }
    return filteredFlights;
  }
);

export const getSortedAndFilteredFlights = createSelector(
  filteredFlightsSelectorV2,
  flightsSelector,
  flightShopProgressSelector,
  sortOptionSelector,
  (flightList, flights, progress, sortOption): IFlightListData[] => {
    switch (sortOption) {
      case "fareScore":
        return [
          ...sorters.orderByRecommendedV3(
            flightList,
            flights!,
            progress === FlightShopStep.ChooseReturn
          ),
        ];
      case "price":
        return [...sorters.orderByPriceV2(flightList)];
      case "departureTime":
        return sorters.orderByDepartureTimeV2(flightList, flights!);
      case "arrivalTime":
        return [...sorters.orderByArrivalTimeV2(flightList, flights!)];
      case "stops":
        return [...sorters.orderByStopsV2(flightList, flights!)];
      case "duration":
        return [...sorters.orderByDurationV2(flightList, flights!)];
      case "stopAndDepartureTime":
        return [...sorters.orderByStopsThenDeparture(flightList, flights!)];

      default:
        return [
          ...sorters.orderByRecommendedV3(
            flightList,
            flights!,
            progress === FlightShopStep.ChooseReturn
          ),
        ];
    }
  }
);

export const isHackerFareInShopListSelector = createSelector(
  flightsSelector,
  getFlightList,
  (flights, flightList) => {
    return flightList.findIndex(
      (flightInList) =>
        flights.fares[flightInList?.fares[0]?.example?.fare]
          ?.multiTicketType === MultiTicketTypeEnum.HackerFare
    ) > -1
      ? MultiTicketTypeEnum.HackerFare
      : MultiTicketTypeEnum.Single;
  }
);

export const getTripResultEventProperties = createSelector(
  getOrigin,
  getDestination,
  getDepartureDate,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  selectedTripSelector,
  getTripDetails,
  getShopTrackingProperties,
  (
    origin,
    destination,
    departureDate,
    outgoingHackerFare,
    returnHackerFare,
    selectedTrip,
    tripDetails,
    shopTrackingProperties
  ) => {
    const selectedFareId = selectedTrip?.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    const fareDetails = tripDetails?.fareDetails.find(
      (f) => f.id === selectedFareId
    );

    return {
      ...(shopTrackingProperties?.properties || {}),
      origin: origin?.id.code.code,
      destination: destination?.id.code.code,
      departureDate: departureDate?.toISOString().slice(0, -1),
      success: true,
      multi_ticket_type: fareDetails?.multiTicketType,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
    };
  }
);

export const getViewedForecastPropertiesForV2 = createSelector(
  predictionSelector,
  getAirEntryProperties,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  getFlightList,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  isHackerFareInShopListSelector,
  selectedTripSelector,
  getTripDetails,
  getShopTrackingProperties,
  (
    prediction,
    airEntryProps,
    isInChooseDepartureStep,
    isInChooseReturnStep,
    flightList,
    outgoingHackerFare,
    returnHackerFare,
    isHackerFareInShopList,
    selectedTrip,
    tripDetails,
    shopTrackingProperties
  ): ViewedForecastProperties | null => {
    if (!airEntryProps) {
      return null;
    }
    let numberOfFares = 0;

    numberOfFares = flightList
      ? flightList.reduce((fareCount: number, flight: any) => {
          return fareCount + flight.fares.length;
        }, 0)
      : 0;

    const selectedFareId = selectedTrip?.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    const fareDetails = tripDetails?.fareDetails.find(
      (f) => f.id === selectedFareId
    );

    return {
      ...airEntryProps,
      ...(shopTrackingProperties?.properties || {}),
      flights_shown: flightList?.length || undefined,
      flights_shown_return: isInChooseReturnStep
        ? flightList?.length
        : undefined,
      fares_shown_outbound: isInChooseDepartureStep ? numberOfFares : undefined,
      fares_shown_return: isInChooseReturnStep ? numberOfFares : undefined,
      slice_direction: isInChooseReturnStep
        ? "return"
        : isInChooseDepartureStep
        ? "outbound"
        : undefined,
      dealness: prediction?.dealness || "",
      recommendation: !prediction
        ? "null"
        : prediction.dealness === Dealness.Wait
        ? "wait"
        : "buy",
      multi_ticket_type: fareDetails?.multiTicketType,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
      multi_ticket_type_shopping:
        isHackerFareInShopList || fareDetails?.multiTicketType,
    };
  }
);

export const getSelectedOutgoingSlicePropertiesV2 = createSelector(
  getViewedForecastPropertiesForV2,
  selectedTripSelector,
  (viewedForecastProperties, selectedTrip): SelectedSliceProperties | null => {
    if (!viewedForecastProperties) {
      return null;
    }

    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedTrip.outgoingFareRating),
    };
  }
);

export const getSelectedReturnSlicePropertiesV2 = createSelector(
  getViewedForecastPropertiesForV2,
  selectedTripSelector,
  (viewedForecastProperties, selectedTrip): SelectedSliceProperties | null => {
    if (!viewedForecastProperties) {
      return null;
    }

    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedTrip.returnFareRating),
    };
  }
);

export const getViewedAmenityReviewProperties = createSelector(
  getSelectedOutgoingSlicePropertiesV2,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  (viewedSliceProperties, outgoingHackerFare, returnHackerFare) => {
    return {
      ...viewedSliceProperties,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
    };
  }
);

export const getOriginCountryCode = createSelector(
  flightsSelector,
  selectedTripSelector,
  (flights, selectedTrip) => {
    const airports = flights?.airports;
    const outgoingSlice = flights?.slices[selectedTrip.outgoingSliceId || ""];
    return (
      airports?.[outgoingSlice?.origin || ""]?.geography?.countryCode || ""
    );
  }
);

export const getDestinationCountryCode = createSelector(
  flightsSelector,
  selectedTripSelector,
  (flights, selectedTrip) => {
    const airports = flights?.airports;
    const outgoingSlice = flights?.slices[selectedTrip.outgoingSliceId || ""];
    return (
      airports?.[outgoingSlice?.destination || ""]?.geography?.countryCode || ""
    );
  }
);

export const getFareSliceByFareId = createSelector(
  flightsSelector,
  (flights: Flights) => (fareId: string) => {
    const fare = flights?.fares?.[fareId];
    const fareSlice = flights?.fareSlices?.[fare?.outbound || ""];
    return fareSlice;
  }
);

export const getOutgoingFareSlice = createSelector(
  flightsSelector,
  selectedTripSelector,
  (flights, selectedTrip) => {
    const fare = flights?.fares?.[selectedTrip?.outgoingFareId || ""];
    const fareSlice = flights?.fareSlices?.[fare?.outbound || ""];
    return fareSlice;
  }
);

// Get all the fares for outbound or return.
export const getFaresSelector = createSelector(
  flightsSelector,
  getHasSetFareClassFilter,
  getFareclassOptionFilter,
  flightShopProgressSelector,
  getReturnFlightList,
  sortOptionSelector,
  filteredFlightsSelectorV2,
  allFiltersSelector,
  hasSetMaxPriceFilterSelector,
  getHasSetDepartureTimeRange,
  getHasSetArrivalTimeRange,
  getFlightShopDefaultSort,
  (
    flights,
    hasAppliedFareClassFilter,
    fareClassFilter,
    progress,
    returnFlightList,
    sortOption,
    filteredFlights,
    allFiltersSelector,
    hasSetMaxPriceFilter,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    flightShopDefaultSort
  ) => {
    const isDeparture = progress === FlightShopStep.ChooseDeparture;
    const flightList = isDeparture ? filteredFlights : returnFlightList;
    const { maxPriceFilter, airlineFilter } = allFiltersSelector;
    if (!flightList || !flights) {
      return [];
    }

    const fares: Array<FilteredFare> = flightList
      .flatMap((flight) => {
        return flight.fares.flatMap((fare) => {
          return { fare: fare, flight: flight };
        });
      })
      .filter((fare) => {
        const fareRating =
          FlightRatingsEnum[
            flights.fareSlices[fare.fare.fareSlice]?.fareShelf.value
          ];
        // Fare filter
        if (
          hasAppliedFareClassFilter &&
          fareRating &&
          !fareClassFilter[fareRating]
        ) {
          return false;
        }
        // Max price filter
        if (
          hasSetMaxPriceFilter &&
          fare.fare?.amount?.fiat?.value &&
          maxPriceFilter < fare.fare.amount.fiat.value
        ) {
          return false;
        }
        // Airline filter
        if (
          airlineFilter.length > 0 &&
          !airlineFilter.includes(
            flights.slices[fare.flight.slice].marketingAirline
          )
        ) {
          return false;
        }
        // Departure & Arrival flight filter
        if (hasSetDepartureTimeRange || hasSetArrivalTimeRange) {
          if (isDeparture) {
            return filters.performTimeRangeFilterV2(
              flights.slices[fare.flight.slice],
              allFiltersSelector.outboundDepartureTimeRange,
              allFiltersSelector.outboundArrivalTimeRange
            );
          } else {
            return filters.performTimeRangeFilterV2(
              flights.slices[fare.flight.slice],
              allFiltersSelector.returnDepartureTimeRange,
              allFiltersSelector.returnArrivalTimeRange
            );
          }
        }

        return true;
      });

    // Sort filtered fares
    switch (sortOption) {
      case "fareScore":
        switch (flightShopDefaultSort) {
          case FlightShopDefaultSort.Recommended:
            return sorters.orderFaresByRecommended(fares, flights);
          case FlightShopDefaultSort.TagsThenPrice:
            return sorters.orderFaresByTagsThenPrice(
              fares,
              flights,
              progress === FlightShopStep.ChooseReturn
            );
          default:
            return sorters.orderFaresByRecommended(fares, flights);
        }
      case "stops":
        return sorters.orderFaresByStops(fares, flights);
      case "departureTime":
        return sorters.orderFaresByDepartureTime(fares, flights);
      case "arrivalTime":
        return sorters.orderFaresByArrivalTime(fares, flights);
      case "duration":
        return sorters.orderFaresByDuration(fares, flights);
      case "stopAndDepartureTime":
        return sorters.orderFaresByStopsThenDeparture(fares, flights);
      case "price":
      default:
        return sorters.orderFaresByPrice(fares);
    }
  }
);

export const getProductLineItems = createSelector(
  shopPricingInfoSelector,
  getSelectedCfarOffer,
  getSelectedScheduleChangeOffer,
  (shopPricingInfo, selectedCfarOffer, selectedScheduleChangeOffer) => {
    if (shopPricingInfo.fare && shopPricingInfo.fare.length > 0) {
      const baseAmount =
        shopPricingInfo.fare[0].pricing.baseAmount.fiat.value || 0;
      const taxesAndFees =
        shopPricingInfo.fare[0].pricing.taxAmount.fiat.value || 0;

      const lineItems = [
        {
          lineTitle: "",
          baseAmount,
          products: {},
          taxesAndFees,
        },
      ];

      if (selectedCfarOffer && selectedCfarOffer.premiumAmount) {
        lineItems[0].products["AirCfar"] = selectedCfarOffer.premiumAmount.fiat;
      }

      if (selectedScheduleChangeOffer) {
        lineItems[0].products["ScheduleChangeDisruptionProtection"] =
          selectedScheduleChangeOffer.quotes[0].pricePerPax.fiat;
      }

      return lineItems;
    }
    return [];
  }
);

export const getTotalAmount = createSelector(
  getProductLineItems,
  (productLineItems) => {
    let total =
      productLineItems[0].baseAmount + productLineItems[0].taxesAndFees;

    Object.keys(productLineItems[0].products).forEach((key) => {
      total += productLineItems[0].products[key].value;
    });

    return total;
  }
);

export const getFareDetails = createSelector(
  selectedTripSelector,
  getTripDetails,
  (selectedTrip, tripDetails) => {
    const selectedFareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;
    const fareDetails = tripDetails?.fareDetails.find(
      (f) => f.id === selectedFareId
    );
    return fareDetails;
  }
);

export const getSelectedTripAirports = createSelector(
  tripSummariesByIdSelector,
  flightsSelector,
  selectedTripSelector,
  (tripSummaries, flights, selectedTrip) => {
    const tripSummary = tripSummaries[selectedTrip.tripId];
    return tripSummary
      ? tripSummary.context.airports
      : flights
      ? flights.airports
      : {};
  }
);

export const getFareMultiTicketType = createSelector(
  getFareDetails,
  (fareDetails) => {
    return fareDetails?.multiTicketType;
  }
);

export const getTrackingProperties = createSelector(
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getPassengersTotal,
  getInfantsOnLapCount,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  selectedTripDetailsSelector,
  shopFilterSelector,
  sortOptionSelector,
  (
    origin,
    destination,
    departureDate,
    returnDate,
    passengerCount,
    infantsOnLapCount,
    isInChooseDepartureStep,
    isInChooseReturnStep,
    tripDetails,
    shopFilter,
    sortOption
  ) => {
    return {
      advance: dayjs(departureDate).diff(dayjs(), "day"),
      origin: !origin
        ? tripDetails
          ? tripDetails.slices[0].originName
          : ""
        : `${origin.id.code.regionType}/${origin.id.code.code}`,
      destination: !destination
        ? tripDetails
          ? tripDetails.slices[0].destinationName
          : ""
        : `${destination.id.code.regionType}/${destination.id.code.code}`,
      origin_code: origin?.id.code.code || "",
      origin_country_code:
        origin?.id.code.code ||
        (tripDetails ? tripDetails.slices[0].originCode : ""),
      origin_label: origin?.label || "",
      destination_code: destination?.id.code.code || "",
      destination_country_code:
        destination?.id.code.code ||
        (tripDetails ? tripDetails.slices[0].destinationCode : ""),
      departure_date: !departureDate
        ? tripDetails
          ? tripDetails.slices[0].departureTime
          : ""
        : dayjs(departureDate).format("YYYY-MM-DD"),
      ...(tripDetails &&
        tripDetails.slices.length > 1 && {
          return_date: dayjs(tripDetails.slices[1].departureTime).format(
            "YYYY-MM-DD"
          ),
        }),
      ...(returnDate && {
        return_date: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      los: returnDate
        ? dayjs(returnDate).diff(dayjs(departureDate), "day")
        : null,
      searched_pax_total: passengerCount,
      searched_pax_total_infant_lap: infantsOnLapCount,
      slice_direction: isInChooseReturnStep
        ? "return"
        : isInChooseDepartureStep
        ? "outbound"
        : undefined,
      sort_order: sortOption,
      trip_filter: shopFilter,
      trip_type:
        returnDate || (tripDetails && tripDetails.slices.length > 0)
          ? "round_trip"
          : "one_way",
    };
  }
);

export * from "./flightShopProgress";
export * from "./fintechSelection";
export * from "./flightShopOffers";
