import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { PriceWatchEntryCard } from "@hopper-b2b/ui";
import { useSessionContext } from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import { passengerCountSelector } from "../../../search/reducer";
import { alertKeySelector } from "../../reducer";
import type { PriceWatchEntryCardConnectorProps } from "./container";

export const FlightPriceWatchEntryCard = (
  props: PriceWatchEntryCardConnectorProps
) => {
  const {
    isWatching,
    prediction,
    createWatch,
    deleteWatch,
    setCreateWatchCallState,
    hasUnsupportedPredictionFilters,
  } = props;
  const [watching, setWatching] = useState(isWatching);
  const { assets } = useContext(ClientContext);
  const sessionContext = useSessionContext();
  const [email, setEmail] = useState(sessionContext.email || "");
  const priceWatchAlertKey = useSelector(alertKeySelector);
  const paxCount = useSelector(passengerCountSelector);

  return (
    <PriceWatchEntryCard
      predictionCopy={prediction.predictionCopy}
      createWatch={createWatch}
      deleteWatch={deleteWatch}
      setCreateWatchCallState={setCreateWatchCallState}
      hasUnsupportedPredictionFilters={hasUnsupportedPredictionFilters}
      bellIcon={assets?.bell}
      email={email}
      onEmailChange={setEmail}
      watching={watching}
      onWatchingChange={setWatching}
      priceWatchAlertKey={priceWatchAlertKey}
      paxCount={paxCount}
    />
  );
};
