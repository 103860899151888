import type { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import type React from "react";
import type {
  Airline,
  Airport,
  FlightItinerarySlice as ApiFlightItinerarySlice,
  BookedFlightItineraryWithDepartureTime,
  Person,
} from "@b2bportal/air-booking-api";
import type {
  FiatPrice,
  Prediction,
  TripContext,
  TripSegment,
  TripSlice,
} from "@b2bportal/air-shopping-api";
import type {
  AmenityStatus,
  BedTypeList,
  CheckInInstructions,
  Lodging,
  LodgingAddress,
  LodgingPrice,
  LodgingSummary,
  NonResizableImage,
  Prices,
  RoomInfo,
  RoomInfoProducts,
  RoomProduct,
  Suggestion,
  TripAdvisorComment,
  TripAdvisorReviews,
} from "@b2bportal/lodging-api";
import type { Product } from "@b2bportal/purchase-api";
import type { SortOption, ViewOption } from "@hopper-b2b/lodging-utils";
import type {
  AirlineCode,
  AirportMap,
  DisruptionProductType,
  FareDetails,
  FareSliceDetails,
  FintechProductOption,
  FlightInfoDetails,
  FlightItinerarySlice,
  GordianSeatSegment,
  HotelItineraryWithType,
  IApiConfig,
  IConfirmationNumber,
  IFlightCardProps,
  IFlyerOption,
  ILodgingFilterState,
  IPerson,
  ITripTerminus,
  LodgingsFilterBoundaries,
  MyTripsFilter,
  MyTripsModalTypes,
  PhoneNumberValidatorEnum,
  PortalItineraryStatusEnum,
  PriceRange,
  RadioOption,
  SelectedSeatsSegment,
  TagInfo,
  TripCategory,
  TripDetails,
  WalletOffer,
} from "@hopper-b2b/types";
import type { IActionButtonProps } from "@hopper-b2b/ui-core";
import type { TypographyProps } from "@material-ui/core";
import type { ClassValue } from "clsx";
import type { Dayjs } from "dayjs";
import type { IActionLink } from "../ActionLinks";
import type { OptionType } from "../B2BAutocomplete/component";
import type { IB2BButton } from "../B2BButton";
import type { IB2BTextFieldProps } from "../B2BTextField";
import type { ICheckoutPaymentFormProps } from "../CheckoutPaymentForm";
import type { IContactSupportModalContentProps } from "../ContactSupportModalContent";
import type { ErrorBannerProps } from "../ErrorBanner";
import type { IErrorModal } from "../ErrorModal";
import type { IErrorPopup } from "../ErrorPopup";
import {
  FareDetailsCard,
  type IFareDetailsCardProps,
} from "../FareDetailsCard";
import { FareDetailsSliceHeader } from "../FareDetailsSliceHeader";
import { B2BFlightCardV2 } from "../FlightCard";
import {
  FlightDetailsCard,
  type IFlightDetailsCardProps,
} from "../FlightDetailsCard";
import {
  FlightDetailsSummary,
  type IFlightDetailsSummaryProps,
} from "../FlightDetailsSummary";
import { FlightShopRow, type IFlightShopRowProps } from "../FlightShopRow";
import {
  FlightSummaryRow,
  type IFlightSummaryRowProps,
} from "../FlightSummaryRow";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import { OutageBanner } from "../OutageBanner";
import {
  type IPassengerCountPickerProps,
  PassengerCountPicker,
} from "../PassengerCountPicker";
import type { IPaymentMethodRadio } from "../PaymentMethodRadio";
import type { IPhoneInputFieldProps } from "../PhoneInputField";
import { SliceDetails, type SliceToShow } from "../SliceDetails";
import type { PriceWatchEntryCardProps } from "../uber/PriceWatchEntryCard/component";
import { Edit } from "./components/Edit";
import type { BannerSeverity } from "./components/NotificationBanner";
import { EmptyChildrenSlot } from "./EmptyChildrenSlot";
import { EmptySlot } from "./EmptySlot";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Slots
  extends Partial<{
    "flight-shop-loading-screen": React.ComponentType<{
      loading: boolean;
      averageLoadingTime?: number;
      className?: string;
      loadingSteps?: Array<{
        title: string;
        description?: string;
        image?: string;
      }>;
      onResetRenderLoadingSteps?: (renderLoadingSteps: boolean) => void;
    }>;
    // Flights / Air
    "fare-card-header": React.ComponentType<{ fareId: string }>;
    "fare-details-card-policy": React.ComponentType<{ fareId: string }>;
    "flight-shop-review-itinerary-policy": React.ComponentType<{
      fareId: string;
    }>;
    "flight-shop-review-itinerary-large-header": React.ComponentType;
    "flight-shop-review-multiticket-banner": React.ComponentType<{
      copy: string;
    }>;
    "flight-shop-review-insurance-section": React.ComponentType<{
      tripDetails: TripDetails;
      isOneWay: boolean;
    }>;
    "flight-shop-transition-loader": React.ComponentType<{
      resetRenderLoader: () => void;
    }>;
    "flight-search-loader": React.ComponentType<any>;
    "flight-search": React.ComponentType<any>;
    "mobile-flight-filter-title": React.ComponentType<{ title: string }>;
    "mobile-flight-number-filter-selection": React.ComponentType<{
      options: { label: AirlineCode; value: AirlineCode }[];
      onChange: (value: string) => void;
      value: string;
      flightNumberOptions: JSX.Element[];
    }>;
    "mobile-flight-price-prediction-content": React.ComponentType<{
      prediction: Prediction;
    }>;
    "price-breakdown-additional-info": React.ComponentType<{
      rewardsBack: string;
    }>;
    "price-range-tag": React.ComponentType<{ value: string; index: number }>;
    "flight-shop-list-mobile-card": React.ComponentType<IFlightCardProps>;
    "flight-shop-list-expanded-section": React.ComponentType<IFlightDetailsCardProps>;
    "fare-details-slice-header": React.ComponentType<{
      origin: string;
      destination?: string;
      date: Date;
      airline: string;
      isHackerFare?: boolean;
    }>;
    "mobile-checkout-cta-section": React.ComponentType<{
      onClick: () => void;
      buttonDisabled?: boolean;
    }>;
    "flight-shop-cta-section": React.ComponentType<{
      totalPrice: FiatPrice;
      discountedPrice: FiatPrice;
      tripCategory: TripCategory;
      onClick: () => void;
    }>;
    "flight-calendar-date-label": React.ComponentType<{
      label: string;
      icon: string;
      dateSelected: string;
      dateSelectedWithoutFormat: Date;
      active: boolean;
      setStartDate?: (date: Date | null) => void;
      setEndDate?: (date: Date | null) => void;
      direction: "outbound" | "return";
      warning?: boolean;
    }>;
    // Calendar improvments
    "desktop-calendar-date-label-controls": React.ComponentType;
    // Virtual Interline
    "fare-details-card": React.ComponentType<IFareDetailsCardProps>;
    "flight-shop-review-itinerary-price-summary-section": React.ComponentType;
    "flight-with-fare-details-summary": React.ComponentType;
    "search-calendar-banner": React.ComponentType;
    "flight-details-summary": React.ComponentType<IFlightDetailsSummaryProps>;
    "flight-shop-row": React.ComponentType<IFlightShopRowProps>;
    "flight-shop-review-itinerary-root-background": React.ComponentType<unknown>;
    "missed-connection-protection-outlined-button": React.ComponentType<unknown>;
    "non-refundable-banner": React.ComponentType<unknown>;
    "traveler-id-match-banner": React.ComponentType;
    "dropdown-icon": React.ComponentType;
    "expandable-flight-card-title-container": React.ComponentType;
    "flight-summary-row": React.ComponentType<IFlightSummaryRowProps>;
    "price-with-decimals": React.ComponentType<{
      className?: string;
      totalCostFiat: FiatPrice | null;
    }>;
    "mobile-flight-details-modal": React.ComponentType<{
      isOutgoing: boolean;
      className?: string;
      departureTime?: string;
      segments: TripSegment[];
      plusDays?: number;
      fareSlice?: FareSliceDetails;
      fareDetails: FareDetails[];
      tripDetails: TripDetails;
      onClose: () => void;
      onChangeFlight?: (isOutbound: boolean) => void;
      locationName: string;
    }>;
    "mobile-flight-summary-row": React.ComponentType<{
      tripSlice: TripSlice;
      airports: AirportMap;
      className: string;
      isReturn?: boolean;
      onClick?: () => void;
      hasSummaryPanelBorder?: boolean;
    }>;
    "trip-review-combination-banner": React.ComponentType<{
      tripDetails: TripDetails;
      airports: AirportMap;
      isMobile: boolean;
      fareDetails: FareDetails;
    }>;
    "mobile-flight-details-expanded": React.ComponentType<{
      fareDetails: FareDetails[];
      isOutgoing: boolean;
      tripDetails: TripDetails;
      onFareClick: (fareId: string) => void;
      className?: string;
      departureTime?: string;
      segments: TripSegment[];
      plusDays?: number;
      header?: JSX.Element;
      fareSlice?: FareSliceDetails;
      onChange?: () => void;
      onMobileContinue?: () => void;
      setExpandedFlight: (expandedFlight: string) => void;
    }>;
    "mobile-locations-button": React.ComponentType<{
      handleEditLocation: () => void;
      locationLabel: string;
      passengerCount: number;
    }>;
    // Hotels / Lodging
    "lodging-card-policy": React.ComponentType<{
      lodgingId: string;
      autoMarginLeft?: boolean;
    }>;
    "lodging-free-cancellation": React.ComponentType<{
      isFreeCancel: boolean;
    }>;
    "lodging-card-cash-back": React.ComponentType<{
      price: LodgingPrice;
    }>;
    "lodging-card-original-price": React.ComponentType<{
      originalPrice: Prices;
      finalPrice: Prices;
    }>;
    "lodging-card-wallet": React.ComponentType;
    "lodging-card-summary": React.ComponentType<{
      currentLodging: Lodging;
    }>;
    // Common
    edit: React.ComponentType<{
      className?: string;
      onClick: () => void;
    }>;
    "add-traveler-form-select": React.ComponentType<{
      options: RadioOption[];
      label: string;
      setOption: (option: RadioOption["value"]) => void;
      selected: RadioOption;
      className: string;
      required?: boolean;
      error?: string;
    }>;
    // Checkout
    "checkout-contact-info-form": React.ComponentType<{
      phone: string;
      countryCode: string;
      email: string;
      onPhoneNumberChanged: (
        number: string,
        countryDialCode: string,
        customIsValid?: (number: string) => PhoneNumberValidatorEnum
      ) => void;
      onEmailChanged: (email: string) => void;
      emailError: string;
      phoneError: string;
      setCountryCode: (code: string) => void;
      disabled?: boolean;
      showEditAction?: boolean;
      onEdit?: () => void;
    }>;
    "passenger-frequent-flyer-select": React.ComponentType<{
      selected: IFlyerOption | null;
      options: IFlyerOption[];
      setOption: (option: IFlyerOption) => void;
    }>;
    "passenger-required-assistance-select": React.ComponentType<{
      assistance: string[];
      assistanceOptions: {
        label: string | JSX.Element;
        value: string;
      }[];
      selected: string;
      optionMenuItems: JSX.Element;
      setAssistance: (assistanceOptions: string[]) => void;
      getLabel: (value: string) => string;
    }>;
    "passenger-required-assistance-redress-number": React.ComponentType;
    "passenger-required-assistance-traveler-number": React.ComponentType;
    "frequent-flyers-add-another-button": React.ComponentType<{
      handleAddAnotherMobile: () => void;
    }>;
    "frequent-flyer-form-modal-bottom-buttons": React.ComponentType<{
      focusedTrackingId: number | null;
      handleSaveFlyer: () => void;
      handleRemoveFlyer: (id: number) => void;
      isSaveButtonDisabled: boolean;
    }>;
    "passenger-passport-country-select": React.ComponentType<{
      selected: RadioOption["value"] | null;
      options: RadioOption[];
      setOption: (option: RadioOption["value"]) => void;
    }>;
    "passenger-input-field-learn-more": React.ComponentType<{
      category: "RedressNumber" | "KnownTravelerNumber";
    }>;
    "flight-review-mixed-cabin-tooltip": React.ComponentType<{
      showDivider?: boolean;
    }>;
    "flight-shop-summary-panel": React.ComponentType<{
      tripSlice: TripSlice;
      airports: AirportMap;
    }>;
    "traveler-select-row": React.ComponentType<{
      className?: string;
      value: string;
      selectRowType: string;
      isSelected: boolean;
      onClickEdit: () => void;
      onClickSelect?: () => void;
      buttonClassName?: string;
      disabled?: boolean;
      isMobile?: boolean;
    }>;
    "traveler-select-row-button-wrap": React.ComponentType<{
      children: JSX.Element;
      onClickSelect?: () => void;
      className: string;
      disabled?: boolean;
      isMobile?: boolean;
      isSelected?: boolean;
    }>;
    "traveler-select-row-empty": React.ComponentType<never>;
    "mobile-traveler-edit-form-buttons": React.ComponentType<{
      travelerId?: string;
      disabled: boolean;
      onSave: () => void;
      onDelete: (travelerId: string) => void;
    }>;
    "desktop-traveler-edit-form-buttons": React.ComponentType<{
      onClose?: () => void;
      onSave?: () => void;
    }>;
    "selected-seat-card": React.ComponentType<{
      selectedSeats: GordianSeatSegment;
      airports: { [key: string]: Airport | undefined };
      idx: number;
      onEditClick?: (idx: number) => void;
      isMobile?: boolean;
    }>;
    "trip-details-links": React.ComponentType<{
      actions: IActionLink[];
    }>;
    "trip-details-summary": React.ComponentType<{
      confirmationNumbers: IConfirmationNumber[];
      onClick: (modalType: MyTripsModalTypes) => void;
      flightInfoDetails: FlightInfoDetails;
      title: string;
      titleTag?: TagInfo | undefined;
      isMultiAirline?: boolean;
      flight?: BookedFlightItineraryWithDepartureTime;
      disruptionBanner?: JSX.Element;
    }>;
    "trip-flights-details-insurance": React.ComponentType<{
      flight: BookedFlightItineraryWithDepartureTime;
      isRoundTrip: boolean;
      airportMap: AirportMap;
    }>;
    "empty-trips-list": React.ComponentType<{
      className?: string;
      tripsFilter: MyTripsFilter;
    }>;
    "self-serve-flight-cancel-loading": React.ComponentType;
    "self-serve-cancellation-error-screen": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      onBack?: () => void;
      className?: string;
    }>;
    "exchange-loader": React.ComponentType<{
      open: boolean;
      message?: string;
      secondaryMessage?: string;
      className?: string;
      onClose?: () => void;
    }>;
    "trips-list-loading-screen": React.ComponentType<{
      open: boolean;
      message?: string;
      secondaryMessage?: string;
      className?: string;
      onClose?: () => void;
    }>;
    "exchange-error-screen": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      onBack?: () => void;
      className?: string;
      modal?: boolean;
      header?: string | JSX.Element;
    }>;
    "self-serve-cancellation-flight-info": React.ComponentType<{
      actions?: ReactNode | ReactNode[];
      disclaimer?: string;
      infoItems: string[];
      subtitle?: ReactNode;
      subtitle2?: ReactNode;
      subtitleProps?: TypographyProps;
      tcHelpProps?: TypographyProps;
      tcHelpText?: ReactNode;
      title?: ReactNode;
      titleProps?: TypographyProps;
      primaryOnClick?: () => void;
      primaryButtonText?: string;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string;
      modal?: boolean;
      contactAirline?: boolean;
      contactCustomerService?: boolean;
      onClose?: () => void;
    }>;
    "exchange-contact-airline-screen": React.ComponentType<{
      actions?: ReactNode | ReactNode[];
      disclaimer?: string;
      infoItems: string[];
      subtitle?: ReactNode;
      subtitle2?: ReactNode;
      subtitleProps?: TypographyProps;
      tcHelpProps?: TypographyProps;
      tcHelpText?: ReactNode;
      title?: ReactNode;
      titleProps?: TypographyProps;
      primaryOnClick?: () => void;
      primaryButtonText?: string;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string;
      modal?: boolean;
      contactAirline?: boolean;
    }>;
    "exchange-landing-screen": React.ComponentType<{
      onContinue: () => void;
      origin: ITripTerminus;
      destination: ITripTerminus;
      handleChangeDates: () => void;
      handleChangeLocations: () => void;
      returnDate: Date;
      departureDate: Date;
      policyTitle: string | JSX.Element;
    }>;
    "lodging-search-mobile-guest-modal": React.ComponentType<IPassengerCountPickerProps>;
    "hotel-shop-header": React.ComponentType<{
      initialDestination: Suggestion;
      initialCheckinDate: string;
      initialCheckoutDate: string;
      openFiltersModal: () => void;
      filters: ILodgingFilterState;
      resetFilters: () => { type: string };
      deleteFilter: (key: string) => void;
      lodgingCount: number;
      lodgingFilteredCount: number;
      sort: SortOption;
      setSort(nextValue: SortOption): void;
      loading?: boolean;
      view?: ViewOption;
    }>;
    "hotel-shop-filters-modal": React.ComponentType<{
      open: boolean;
      filters: ILodgingFilterState;
      closeFiltersModal: () => void;
      onFilterPriceRangeChange: (
        nextValue: Pick<PriceRange, "min" | "max">
      ) => void;
      sort?: SortOption;
      setSort?: (sortOption: SortOption) => {
        type: string;
        value: any;
      };
      filterBoundaries: LodgingsFilterBoundaries;
      onAmenitiesChange: (value: string[]) => void;
      onAmenitiesTypeChange: (value: boolean) => void;
      resetFilters: () => void;
      onStarRatingChange(nextValue: number[]): void;
      onUserRatingChange(nextValue: number): void;
      onFreeCancellationChange: (value: boolean) => void;
      onResetFilters: () => void;
    }>;
    "hotel-shop-search-modal": React.ComponentType<{
      open: boolean;
      onClose: () => void;
      LodgingSearchRoot: JSX.Element;
      title: string;
    }>;
    "hotel-shop-reviews-subheading": React.ComponentType;
    "hotel-shop-comment": React.ComponentType<
      Omit<TripAdvisorComment, "author">
    >;
    "flight-search-mobile-header-left-content": React.ComponentType<{
      className: string;
      onClick: () => void;
      content: ReactNode;
      searchStateStep?: number;
    }>;
    "trips-confirmation-modal-copy-and-go-button": React.ComponentType<{
      className?: string;
      locator: string;
      redirectUrl: string;
      onClick: () => void;
      "aria-label": string;
    }>;
    "trips-error-modal-content": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string | JSX.Element;
      onBack?: () => void;
      className?: string;
    }>;
    "flight-shop-location-search-origin-autocomplete": React.ComponentType<{
      hideIcon?: boolean;
      className?: string;
      customIcon?: JSX.Element;
      customClearIcon?: JSX.Element;
      getOptionSelected: (
        option: OptionType<ITripTerminus>,
        value: ITripTerminus
      ) => boolean;
      label: string;
      loadingText?: string;
      noOptionsText?: JSX.Element;
      onBlur: () => void;
      onFocus: () => void;
      popperClassName?: string;
      popupIcon?: JSX.Element;
      setValue: (value: ITripTerminus) => void;
      value: ITripTerminus;
    }>;
    "flight-shop-location-search-destination-autocomplete": React.ComponentType<{
      hideIcon?: boolean;
      className?: string;
      customIcon?: JSX.Element;
      customClearIcon?: JSX.Element;
      getOptionSelected: (
        option: OptionType<ITripTerminus>,
        value: ITripTerminus
      ) => boolean;
      label: string;
      loadingText?: string;
      noOptionsText?: JSX.Element;
      onBlur: () => void;
      onFocus: () => void;
      popperClassName?: string;
      popupIcon?: JSX.Element;
      setValue: (value: ITripTerminus) => void;
      value: ITripTerminus;
    }>;
    "flight-shop-mobile-ftu-banner": React.ComponentType<unknown>;
    "lodging-details-screen-header": React.ComponentType<{
      lodging?: Lodging;
      isMobile?: boolean;
      children: JSX.Element;
    }>;
    "lodging-details-screen-gallery-show-all-button": React.ComponentType<IActionButtonProps>;
    "lodging-card-amenities": React.ComponentType<{
      amenities: AmenityStatus[];
      className?: string;
    }>;
    "mytrips-landing-page-icon": React.ComponentType;
    "mytrips-flight-icon": React.ComponentType;
    "mytrips-hotel-icon": React.ComponentType;
    "hotel-details-gallery-header": React.ComponentType<{
      onBack: () => void;
      close?: boolean;
      title?: string;
    }>;
    "hotel-details-gallery-grid": React.ComponentType<{
      carouselIndex: number;
      imageUrlsArray: string[];
      filteredMedia: NonResizableImage[];
      handleImageOnClick: (index: number) => void;
    }>;
    "flight-price-drop-protection-banner": React.ComponentType<{
      isEligible: boolean;
      showMore: boolean;
    }>;
    "hotel-shop-map": React.ComponentType<{
      lodgingCoordinates: string;
      lodgingAddress: LodgingAddress;
      lodgingName: string;
    }>;
    "calendar-picker": React.ComponentType;
    "hotel-room-details-header": React.ComponentType<{
      onBack?: () => void;
    }>;
    "hotel-room-details-header-title": React.ComponentType<{
      title: string;
    }>;
    "hotel-room-details-select": React.ComponentType<{
      room: RoomInfoProducts;
      onChange: (product: RoomProduct) => void;
      nights: number;
      DetailsModal: JSX.Element;
      selectedId: string;
    }>;
    "hotel-room-details-modal": React.ComponentType<
      RoomInfo & {
        open: boolean;
        onClose(): void;
      }
    >;
    "mytrips-traveler-icon": React.ComponentType<{
      age?: number;
    }>;
    "mytrips-contact-support-image": React.ComponentType;
    "flight-details-timeline": React.ComponentType<{
      className?: string;
      departureTime: string;
      onClick?: () => void;
      planeInfo?: string;
      fareClass?: string;
      segments: TripSegment[];
      showTitle?: boolean;
      plusDays?: number;
      header?: JSX.Element;
      fareSlice?: FareSliceDetails;
      isMixedCabinClass?: boolean;
      tripSlice?: FlightItinerarySlice;
      onChange?: () => void;
      renderAirlineIconSection?: boolean;
    }>;
    "lodging-availability-search": React.ComponentType;
    "hotel-room-details-modal-header": React.ComponentType<{
      name: string;
      maxAdults: number;
      beds: BedTypeList;
    }>;
    "hotel-details-policies": React.ComponentType<{
      checkInInstructions: CheckInInstructions;
    }>;
    "hotel-details-cta": React.ComponentType<{
      departureDate: Dayjs;
      returnDate: Dayjs;
      roomRate: RoomProduct;
      nightsDuration: number;
      minAllowedDate?: Date;
      maxAllowedDate?: Date;
      actionButtonMessage?: string;
      onDateChange: (startDate: Dayjs, endDate: Dayjs) => void;
    }>;
    "hotel-details-review": React.ComponentType<{
      title: string;
      text: string;
      created: string;
      score: number;
    }>;
    "hotel-room-details-cta": React.ComponentType<{
      onClick: () => void;
      selectedProduct: RoomProduct;
      message?: string;
    }>;
    "hotel-summary-price": React.ComponentType<{
      price: LodgingPrice;
      loading: boolean;
    }>;
    "mytrips-list": React.ComponentType<{
      isMobile: boolean;
      apiConfig: IApiConfig;
      onSupportClick: () => void;
    }>;
    "lodging-room-select-header": React.ComponentType<{
      titleId: string;
    }>;
    "lodging-room-card": React.ComponentType<{
      room: RoomInfoProducts;
      onRateSelected: (roomId: string, rateId: string) => void;
      onSelectedRoomChange: (roomId: string) => void;
      currSelectedRoom: string;
      nights: number;
      onRateChange: (roomId: string, rateId: string) => void;
    }>;
    "mytrips-trip-card": React.ComponentType<{
      onClick: () => void;
      destination: string;
      dates: string;
      details: string;
      status: PortalItineraryStatusEnum;
    }>;
    "flight-shop-mobile-header": React.ComponentType;
    "lodging-availability-empty-state": React.ComponentType<{
      resetFilters?: () => void;
    }>;
    "text-input-field": React.ComponentType<IB2BTextFieldProps>;
    "phone-input-field": React.ComponentType<IPhoneInputFieldProps>;
    "number-input-field": React.ComponentType<IB2BTextFieldProps>;
    "mytrips-notification-banner": React.ComponentType<{
      className: ClassValue;
      content?: JSX.Element;
      cta?: string;
      hideIcon?: boolean;
      html?: string;
      label?: string;
      severity?: BannerSeverity;
      strongLabel?: string;
    }>;
    "payment-method-radio": React.ComponentType<IPaymentMethodRadio>;
    "checkout-payment-form": React.ComponentType<ICheckoutPaymentFormProps>;
    "flights-fintech-selection-page-option": React.ComponentType<{
      title: string;
      description: string;
      checked?: boolean;
      disabled: boolean;
      useCheckbox?: boolean;
      onClick?: () => void;
      setChecked?: (checked: boolean) => void;
      iconName?: string;
      pricePerPax?: string;
      openInfoSheet: () => void;
      product?: Product | DisruptionProductType;
    }>;
    "flight-fintech-selection-page-decline-all": React.ComponentType<{
      title: string;
      description: string;
      checked?: boolean;
      disabled: boolean;
      setChecked?: (checked: boolean) => void;
    }>;
    "flights-fintech-cfar-fintech-purchase": React.ComponentType<{
      options: FintechProductOption[];
      headerTitle?: string;
      headerSubtitle: string | JSX.Element;
      ctaLabel: string;
      handleContinue?: () => void;
      headerImgSrc?: string;
      className?: string;
      clientAssets?: Record<string, any>;
      modal?: boolean;
      disabled?: boolean;
      loading?: boolean;
      isHalfSheet?: boolean;
    }>;
    "price-breakdown-discount-price-line": React.ComponentType;
    "cash-back-ftu-discount": React.ComponentType<{
      price: FiatPrice;
      passengers: IPerson[];
    }>;
    "flight-mobile-fare-details-trip-price": React.ComponentType;
    "hotel-shop-details-modal": React.ComponentType<{
      title?: ReactNode;
      subtitle?: ReactNode;
      onClose: () => void;
      open: boolean;
      fullScreen?: boolean;
    }>;
    "traveller-form-modal": React.ComponentType<{
      title?: ReactNode;
      subtitle?: ReactNode;
      onClose: () => void;
      open: boolean;
      fullScreen?: boolean;
    }>;
    "frequent-flyer-form-modal": React.ComponentType<{
      title?: ReactNode;
      subtitle?: ReactNode;
      onClose: () => void;
      open: boolean;
      fullScreen?: boolean;
      bottomButton?: JSX.Element;
    }>;
    "payment-form-modal": React.ComponentType<{
      title?: ReactNode;
      subtitle?: ReactNode;
      onClose: () => void;
      open: boolean;
      fullScreen?: boolean;
    }>;
    "hotel-list-map-card": React.ComponentType<{
      onClick: () => void;
    }>;
    "header-extra-action": React.ComponentType<never>;
    "header-left-item": React.ComponentType<never>;
    "header-title": React.ComponentType<never>;
    "header-subtitle": React.ComponentType<never>;
    "slice-details": React.ComponentType<{
      airlineMap: Record<string, Airline | undefined>;
      airportMap: Record<string, Airport | undefined>;
      flight: BookedFlightItineraryWithDepartureTime;
      isOutgoing: boolean;
      openScheduleChangeModal: () => void;
      passengerMap: Record<string, Person>;
      seatSegments?: SelectedSeatsSegment[];
      slice: ApiFlightItinerarySlice;
      sliceToShow?: SliceToShow;
    }>;
    "hotel-cancellation-confirmation-screen": React.ComponentType<{
      hotel: HotelItineraryWithType;
      title: string;
      subtitle: string | string[];
      primaryOnClick: () => void;
      className?: string;
    }>;
    "hotel-cancellation-loader": React.ComponentType<{
      loading: boolean;
      averageLoadingTime?: number;
      className?: string;
      loadingSteps?: Array<{
        title: string;
      }>;
      onResetRenderLoadingSteps?: (renderLoadingSteps: boolean) => void;
    }>;
    "hotel-voucher-offer-banner": React.ComponentType<{
      isEligible: boolean;
      showMore: boolean;
      minimal?: boolean;
      offer?: WalletOffer;
      offers?: WalletOffer[];
      onClick: () => void;
    }>;
    "contact-support-modal-content": React.ComponentType<IContactSupportModalContentProps>;
    "mytrips-policy-modal-content": React.ComponentType<{
      hotel: HotelItineraryWithType;
      onClick: () => void;
    }>;
    "payment-wallet-content": React.ComponentType;
    "mytrips-modal-close-icon": React.ComponentType;
    "3ds-challenge": React.ComponentType<
      PropsWithChildren<{
        showChallenge: boolean;
      }>
    >;
    "hotel-cancellation-summary-panel": React.ComponentType<{
      checkIn: string;
      checkOut: string;
      lodging: LodgingSummary;
      refundAmount?: string;
    }>;
    "hotel-search-autocomplete": React.ComponentType<{
      id: string;
      defaultValue?: unknown;
      label: string;
      disabled?: boolean;
      fetch: (
        apiConfig: IApiConfig | undefined,
        search: string,
        callback: (newOptions: unknown[]) => void
      ) => Promise<void>;
      onChange: (value: unknown) => void;
      sortOptions?: (options?: unknown[]) => unknown[];
      groupBy?: (option: unknown) => string;
      getOptionLabel?: (option: unknown) => string;
      getGroupLabelKey?: (group: string) => string;
      icon?: string;
      onOpen?: () => void;
      paperClassName?: string;
      className?: string;
      shrinkLabel?: boolean;
      animated?: true;
      renderOption?: (option: unknown) => React.ReactNode;
    }>;
    "self-serve-flight-itinerary-summary": React.ComponentType<{
      context: TripContext;
      slice: ApiFlightItinerarySlice;
      onClick?: () => void;
    }>;
    "default-outage-banner": React.ComponentType<{
      enabled?: boolean;
      bannerTitle: string;
      bannerSubtitle: string;
    }>;
    tripadvisor: React.ComponentType<{
      tripAdvisorReviews?: TripAdvisorReviews;
      className?: string;
    }>;
    "tripadvisor-badge": React.ComponentType<{
      scoreOutOfFive: number;
      withTripAdvisorIcon: boolean;
      translateToTheLeft?: boolean;
    }>;
    "summary-trip-advisor": React.ComponentType<{
      reviewsCount: number;
      scoreOutOfFive: number;
    }>;
    "lodging-map-back-button": React.ComponentType<
      HTMLAttributes<HTMLButtonElement>
    >;
    "lodging-map-search-area-button": React.ComponentType<IB2BButton>;
    "radio-dropdown": React.ComponentType<{
      buttonClassName?: string;
      popoverClassName?: string;
      dropdownLabel: string;
      options: RadioOption[];
      selected: RadioOption["value"];
      setOption: (option: string) => void;
      radioLabelPlacement?: "start" | "end";
      showDropdownContentOnly?: boolean;
      groupAriaLabel?: string;
      buttonAriaLabel?: string;
      optionProps?: unknown;
    }>;
    "error-banner": React.ComponentType<ErrorBannerProps>;
    "error-popup": React.ComponentType<IErrorPopup>;
    "error-modal": React.ComponentType<IErrorModal>;
    "best-price-guarantee-banner": React.ComponentType<{
      product: Product;
      className?: string;
    }>;
    "installments-banner": React.ComponentType;
    "passenger-form-mobile-top-save-right-button": React.ComponentType<{
      className: string;
      onClick: () => void;
    }>;
    "flight-confirmation-more-information-container": React.ComponentType<never>;
    "price-watch-entry-card": React.ComponentType<PriceWatchEntryCardProps>;
  }> {}

export type SlotProps<T extends keyof Slots> = React.ComponentProps<Slots[T]>;

export const defaultSlots: Slots = {
  "fare-card-header": EmptySlot,
  edit: Edit,
  "flight-shop-list-mobile-card": B2BFlightCardV2,
  "flight-shop-list-expanded-section": FlightDetailsCard,
  "fare-details-slice-header": FareDetailsSliceHeader,
  "fare-details-card": FareDetailsCard,
  "flight-details-summary": FlightDetailsSummary,
  "flight-review-mixed-cabin-tooltip": MixedCabinToolTip,
  "flight-shop-row": FlightShopRow,
  "flight-summary-row": FlightSummaryRow,
  "trip-review-combination-banner": EmptySlot,
  "flight-shop-review-itinerary-root-background": EmptySlot,
  "missed-connection-protection-outlined-button": EmptySlot,
  "non-refundable-banner": EmptySlot,
  "traveler-id-match-banner": EmptySlot,
  "mobile-flight-details-expanded": EmptySlot,
  "mobile-locations-button": EmptySlot,
  "lodging-search-mobile-guest-modal": PassengerCountPicker,
  "search-calendar-banner": EmptySlot,
  "flight-shop-mobile-ftu-banner": EmptySlot,
  "mytrips-landing-page-icon": EmptySlot,
  "mytrips-flight-icon": EmptySlot,
  "mytrips-hotel-icon": EmptySlot,
  "mytrips-traveler-icon": EmptySlot,
  "mytrips-contact-support-image": EmptySlot,
  "flight-details-timeline": EmptySlot,
  "mytrips-notification-banner": EmptySlot,
  "traveler-select-row-empty": EmptySlot,
  "price-breakdown-discount-price-line": EmptySlot,
  "cash-back-ftu-discount": EmptySlot,
  "hotel-list-map-card": EmptySlot,
  "slice-details": SliceDetails,
  "3ds-challenge": EmptyChildrenSlot,
  "default-outage-banner": OutageBanner,
};
