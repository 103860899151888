import { useEffect, useState } from "react";

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isLargeDesktop, setIsLargeDesktop] = useState(false);
  const [isDesktopAndUp, setIsDesktopAndUp] = useState(false);

  useEffect(() => {
    const windowSizeHandler = () => {
      const width = window.innerWidth;
      setIsMobile(width < breakpoints.sm);
      setIsTablet(width >= breakpoints.sm && width < breakpoints.lg);
      setIsDesktop(width >= breakpoints.lg && width < breakpoints.xl);
      setIsLargeDesktop(width >= breakpoints.xl && width < breakpoints.xxl);
      setIsDesktopAndUp(width >= breakpoints.lg);
    };

    window.addEventListener("resize", windowSizeHandler);

    // Call windowSizeHandler initially to set the correct device type
    windowSizeHandler();

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  const isDetectingDeviceType = !isMobile && !isTablet && !isDesktopAndUp;

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isDesktopAndUp,
    isDetectingDeviceType,
  };
};

export const getDeviceType = () => {
  const width = window.innerWidth;
  return {
    isMobile: width < breakpoints.sm,
    isTablet: width >= breakpoints.sm && width < breakpoints.lg,
    isDesktop: width >= breakpoints.lg && width < breakpoints.xl,
    isLargeDesktop: width >= breakpoints.xl && width < breakpoints.xxl,
    isDesktopAndUp: width >= breakpoints.lg,
  };
};
