import {
  forwardRef,
  type ReactNode,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { InputContainer, InputError } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./styles.module.scss";

export type InputTextProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type"
> &
  PlatformComponentProps & {
    label: string;
    startContent?: ReactNode;
    endContent?: ReactNode;
    error?: Error;
    type: "text" | "number" | "email" | "tel";
  };

const COMPONENT_KEY = CoreUiComponents.InputText;

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    { label, startContent, endContent, error, className, ...inputProps },
    ref
  ) => {
    const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
    const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, {
      error: error != null,
      disabled:
        inputProps.disabled != null && inputProps.disabled
          ? inputProps.disabled
          : false,
    });

    const reactId = useId();

    const id = useMemo(
      () => (inputProps.id != null ? inputProps.id : reactId),
      [inputProps.id, reactId]
    );

    const [value, setValue] = useState(
      inputProps.value ?? inputProps.defaultValue
    );

    useEffect(() => {
      setValue(inputProps.value);
    }, [inputProps.value]);

    return (
      <>
        <InputContainer
          className={clsx(block, className)}
          id={id}
          label={label}
          disabled={inputProps.disabled}
          startContent={startContent}
          endContent={endContent}
          hasError={error != null}
          hasValue={value != null && value !== ""}
        >
          <input
            {...inputProps}
            ref={ref}
            className={cn("input")}
            id={id}
            aria-label={label}
            onChange={(e) => {
              setValue(e.target.value);
              inputProps?.onChange?.(e);
            }}
          />
        </InputContainer>
        <InputError error={error} />
      </>
    );
  }
);
