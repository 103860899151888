import {
  createContext,
  type MutableRefObject,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import type { RawEvent, AttributeValueMap } from "./types";

export type TrackingAttributesMapRef = MutableRefObject<AttributeValueMap>;

const emptyAttributes = {} as AttributeValueMap;

const TrackingAttributesContext = createContext<
  | {
      setTrackingAttribute: <T extends keyof AttributeValueMap>(
        attributeName: T,
        value: AttributeValueMap[T]
      ) => void;
      setTrackingAttributes: (newAttributes: AttributeValueMap) => void;
      trackingAttributes: TrackingAttributesMapRef;
      useTrackEvent: () => EventService;
      useTrackOnLoad: EventService;
      useTrackOnce: EventServiceByCondition;
    }
  | undefined
>(undefined);

export const useEventTracking = () => {
  const context = useContext(TrackingAttributesContext);
  const mockedAttributesRef = useRef<AttributeValueMap>(emptyAttributes);
  if (!context) {
    console.warn(
      `Must be used within a TrackingAttributesProvider. Add a TrackingProvider to your root App component`
    );
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTrackingAttribute: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTrackingAttributes: () => {},
      trackingAttributes: mockedAttributesRef,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useTrackEvent: () => () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useTrackOnLoad: () => () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useTrackOnce: () => () => {},
    };
  }
  return context;
};

// replace type with tracked event type
type EventService = (event: RawEvent) => void;

type EventServiceByCondition = (condition: boolean, event: RawEvent) => void;

type EventServiceHook = () => {
  trackEvent: EventService;
};
export const TrackingProvider = ({
  children,
  handlers,
}: PropsWithChildren<{
  handlers: EventServiceHook[];
}>) => {
  // Use a ref to store attributes
  const trackingAttributes = useRef<AttributeValueMap>(emptyAttributes);
  // Callback to set attributes with type safety
  const setTrackingAttribute = useCallback(
    <T extends keyof AttributeValueMap>(
      attributeName: T,
      value: AttributeValueMap[T]
    ) => {
      if (value == null) {
        console.error(
          `Trying to set ${attributeName} attribute in the tracking context but the value is null or undefined`
        );
        return;
      }
      trackingAttributes.current[attributeName] = value;
    },
    []
  );

  const setTrackingAttributes = useCallback(
    (newAttributes: AttributeValueMap) => {
      trackingAttributes.current = {
        ...trackingAttributes.current,
        ...newAttributes,
      };
    },
    []
  );

  const useTrackEvent = useCallback((): EventService => {
    const eventTrackers = handlers.map((handler) => handler());
    return (event: RawEvent) =>
      eventTrackers.map((tracker) => tracker.trackEvent(event));
  }, [handlers]);

  const useTrackOnLoad: EventService = (event) => {
    const trackEvent = useTrackEvent();
    useEffect(() => {
      trackEvent(event);
    }, []);
  };

  const useTrackOnce: EventServiceByCondition = (condition, event) => {
    const isTracked = useRef(false);

    const trackEvent = useTrackEvent();
    useEffect(() => {
      if (condition && !isTracked.current) {
        trackEvent(event);
        isTracked.current = true;
      }
    }, [condition]);
  };

  return (
    <TrackingAttributesContext.Provider
      value={{
        setTrackingAttributes,
        setTrackingAttribute,
        trackingAttributes,
        useTrackEvent,
        useTrackOnLoad,
        useTrackOnce,
      }}
    >
      {children}
    </TrackingAttributesContext.Provider>
  );
};
