import type { QuoteRequest, CipherText } from "@b2bportal/purchase-api";
import { scheduleQuote } from "@hopper-b2b/api";

import {
  CartQuoteErrorModal,
  type CartQuoteError,
  CartQuoteState,
} from "../../../../types";
import type { CartContext } from "../../context";
import { getCartQuoteCustomerEmail, getCartQuoteProducts } from "../selectors";

export const schedulePriceQuoteService = (
  context: CartContext,
  _event: unknown
) =>
  new Promise((resolve, reject) => {
    const products = getCartQuoteProducts({ context });
    const email = getCartQuoteCustomerEmail({ context });
    const cartQuoteError: Omit<CartQuoteError, "type"> = {
      failedAt: CartQuoteState.schedule,
    };

    if (!products.length) {
      reject({
        ...cartQuoteError,
        type: CartQuoteErrorModal.NoProducts,
      });
    }

    if (!email) {
      reject({
        ...cartQuoteError,
        type: CartQuoteErrorModal.NoEmail,
      });
    }

    const params: QuoteRequest = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      emailAddress: email!,
      products: products,
      modify: [],
    };

    scheduleQuote(params)
      .then((result: CipherText) => {
        if (result?.value) {
          resolve(result);
        } else {
          reject({
            ...cartQuoteError,
            type: CartQuoteErrorModal.NoCipherText,
          });
        }
      })
      .catch((e) => {
        reject({
          ...cartQuoteError,
          type: CartQuoteErrorModal.NoCipherText,
        });
      });
  });
