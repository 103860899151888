import React, { useState, useEffect, useCallback } from "react";
import { Box, Link } from "@material-ui/core";
import { Swipeable } from "react-swipeable";
import clsx from "clsx";

import { CarouselImageSlider } from "./components/ImageSlider";
import { CarouselArrow } from "./components/Arrow";
import { DotsIndicator } from "./components/DotsIndicator";
import { DefaultLodgingPlaceholder } from "./components/DefaultLodgingPlaceholder";
import {
  getNextValidIndex,
  CarouselMoveDirection,
} from "@hopper-b2b/utilities";
import "./styles.scss";

export interface ICarouselProps {
  className?: string;
  imageUrlsArray: string[];
  placeholder?: JSX.Element;
  initialIndex?: number;
  hideArrows?: boolean;
  hideDots?: boolean;
  smallVersion?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onNavigateToPrevSlide?: () => void;
  onNavigateToNextSlide?: () => void;
  setIndex?: (index: number) => void;
  preventDefaultTouchmoveEvent?: boolean;
}

export const Carousel: React.FC<ICarouselProps> = React.memo(
  ({
    className,
    imageUrlsArray,
    placeholder,
    initialIndex,
    hideArrows,
    hideDots,
    smallVersion,
    onClick,
    onNavigateToPrevSlide,
    onNavigateToNextSlide,
    setIndex,
    preventDefaultTouchmoveEvent = true,
  }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(initialIndex ?? 0);
    const [hasInitialValidIndex, setHasInitialValidIndex] =
      useState<boolean>(false);
    const [validIndexes, setValidIndexes] = useState<number[]>([]);
    const [hasFoundAllValidIndexes, setHasFoundAllValidIndexes] =
      useState<boolean>(false);
    const [goLeft, setGoLeft] = useState<boolean>(false);
    const [goRight, setGoRight] = useState<boolean>(false);

    // If the caller changes the initial index, this signals that we should also revert the carousel to show that index.
    React.useEffect(() => {
      setCurrentIndex(initialIndex ?? 0);
    }, [setCurrentIndex, initialIndex]);

    const updateValidIndexes = useCallback(
      (
        newValidIndex: number | null,
        moveDirection: CarouselMoveDirection,
        updateCurrentIndex: boolean
      ) => {
        if (newValidIndex === null) {
          setHasFoundAllValidIndexes(true);
          return null;
        }

        // this condition checks whether it has completed the carousel loop or not
        if (
          !hasFoundAllValidIndexes &&
          validIndexes.length > 1 &&
          ((validIndexes[0] === newValidIndex &&
            moveDirection === CarouselMoveDirection.Right) ||
            (validIndexes[validIndexes.length - 1] === newValidIndex &&
              moveDirection === CarouselMoveDirection.Left))
        ) {
          setHasFoundAllValidIndexes(true);
        } else if (!validIndexes.includes(newValidIndex)) {
          if (moveDirection === CarouselMoveDirection.Right) {
            validIndexes.push(newValidIndex);
          } else {
            validIndexes.unshift(newValidIndex);
          }
          setValidIndexes(validIndexes);
        }
        updateCurrentIndex && setCurrentIndex(newValidIndex);
        return null;
      },
      [hasFoundAllValidIndexes, validIndexes]
    );

    useEffect(() => {
      async function getInitialValidIndex() {
        if (!hasInitialValidIndex) {
          const newValidIndex = await getNextValidIndex(
            imageUrlsArray,
            currentIndex,
            CarouselMoveDirection.Right
          );
          updateValidIndexes(newValidIndex, CarouselMoveDirection.Right, true);
          setHasInitialValidIndex(true);
        }
      }

      getInitialValidIndex();
    }, [
      updateValidIndexes,
      imageUrlsArray,
      currentIndex,
      hasInitialValidIndex,
    ]);

    const handleNextValidIndex = useCallback(
      async (
        imageUrlsArray: string[],
        indexUnvalidated: number,
        moveDirection: CarouselMoveDirection,
        updateCurrentIndex: boolean
      ) => {
        const newValidIndex = await getNextValidIndex(
          imageUrlsArray,
          indexUnvalidated,
          moveDirection
        );
        updateValidIndexes(newValidIndex, moveDirection, updateCurrentIndex);
      },
      [updateValidIndexes, setIndex]
    );

    const validatePrevSlide = useCallback(
      (updateCurrentIndex: boolean) => {
        if (hasFoundAllValidIndexes && validIndexes.length !== 0) {
          const index = validIndexes.findIndex((ind) => ind === currentIndex);
          const prevIndex =
            (index - 1 + validIndexes.length) % validIndexes.length;
          updateCurrentIndex && setCurrentIndex(validIndexes[prevIndex]);
        } else if (imageUrlsArray.length !== 0) {
          const prevIndexUnvalidated =
            (currentIndex - 1 + imageUrlsArray.length) % imageUrlsArray.length;
          handleNextValidIndex(
            imageUrlsArray,
            prevIndexUnvalidated,
            CarouselMoveDirection.Left,
            updateCurrentIndex
          );
        }
      },
      [
        handleNextValidIndex,
        hasFoundAllValidIndexes,
        imageUrlsArray,
        currentIndex,
        validIndexes,
      ]
    );

    const validateNextSlide = useCallback(
      (updateCurrentIndex: boolean) => {
        if (hasFoundAllValidIndexes && validIndexes.length !== 0) {
          const index = validIndexes.findIndex((ind) => ind === currentIndex);
          const nextIndex = (index + 1) % validIndexes.length;
          updateCurrentIndex && setCurrentIndex(validIndexes[nextIndex]);
        } else if (imageUrlsArray.length !== 0) {
          const nextIndexUnvalidated =
            (currentIndex + 1) % imageUrlsArray.length;
          handleNextValidIndex(
            imageUrlsArray,
            nextIndexUnvalidated,
            CarouselMoveDirection.Right,
            updateCurrentIndex
          );
        }
      },
      [
        handleNextValidIndex,
        hasFoundAllValidIndexes,
        imageUrlsArray,
        currentIndex,
        validIndexes,
      ]
    );

    React.useEffect(() => {
      if (setIndex) setIndex(currentIndex);
    }, [currentIndex, setIndex]);

    const goToPrevSlide = useCallback(() => {
      !hideDots && setGoLeft(true);
      validatePrevSlide(true);
      onNavigateToPrevSlide && onNavigateToPrevSlide();
    }, [onNavigateToPrevSlide, validatePrevSlide, hideDots]);

    const goToNextSlide = useCallback(() => {
      !hideDots && setGoRight(true);
      validateNextSlide(true);
      onNavigateToNextSlide && onNavigateToNextSlide();
    }, [onNavigateToNextSlide, validateNextSlide, hideDots]);

    const imageToShow =
      validIndexes.length && imageUrlsArray && imageUrlsArray.length > 0 ? (
        <CarouselImageSlider url={imageUrlsArray[currentIndex]} />
      ) : (
        <Box className="image-slider-placeholder">
          {placeholder ?? <DefaultLodgingPlaceholder />}
        </Box>
      );

    return (
      <Swipeable
        className={clsx("carousel-swipeable-wrapper", className)}
        preventDefaultTouchmoveEvent={preventDefaultTouchmoveEvent}
        onSwipedLeft={goToNextSlide}
        onSwipedRight={goToPrevSlide}
      >
        <Box className="carousel">
          {onClick && (
            <Link
              className="carousel-image-slider-button-wrapper"
              component="button"
              onClick={onClick}
            >
              {imageToShow}
            </Link>
          )}
          {!onClick && imageToShow}
          {!hideDots &&
            validIndexes.length > 0 &&
            imageUrlsArray.length > 1 && (
              <DotsIndicator
                goLeft={goLeft}
                setGoLeft={setGoLeft}
                goRight={goRight}
                setGoRight={setGoRight}
                smallVersion={smallVersion}
              />
            )}
          {!hideArrows &&
            validIndexes.length > 0 &&
            imageUrlsArray.length > 1 && (
              <>
                <CarouselArrow
                  direction="left"
                  onClickEvent={goToPrevSlide}
                ></CarouselArrow>
                <CarouselArrow
                  direction="right"
                  onClickEvent={goToNextSlide}
                ></CarouselArrow>
              </>
            )}
        </Box>
      </Swipeable>
    );
  }
);
