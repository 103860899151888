import { HotelSortOptionEnum, TrackingProperties } from "@overrides/types";

export type HotelRoomRateTrackingProperties = {
  properties: {
    refundable: boolean;
    refundable_type: string;
  };
} & { encryptedProperties?: TrackingProperties["encryptedProperties"] };

export const fallbackHotelRoomRateTrackingProperties = {
  properties: {
    refundable: false,
    refundable_type: "",
  },
};

export type HotelRoomTrackingProperties = {
  // Given trackingPropertiesV2 is still not returned by the BFF
  // the selector adds the required properties for room rates related events
  properties: {
    selectable_rates_count: number;
    room_type_name: string;
    room_index_in_shop: number;
    lodging_name: string;
    number_of_photos: number;
    city_name: string;
    country_name: string;
    lodging_id: string;
  };
} & { encryptedProperties?: TrackingProperties["encryptedProperties"] };

export const fallbackHotelRoomInfoTrackingProperties = {
  selectable_rates_count: 0,
  room_type_name: "",
};

export const fallbackHotelRoomTrackingProperties = {
  properties: {
    selectable_rates_count: 0,
    room_type_name: "",
    room_index_in_shop: 0,
    lodging_name: "",
    number_of_photos: 0,
    city_name: "",
    country_name: "",
    lodging_id: "",
  },
};

export type SelectedRoomInfoTrackingProperties = {
  selectable_rates_count: number;
  room_type_name: string;
  number_of_photos: number;
};

export type HotelShopTrackingProperties = {
  properties: {
    hopper_preferred_hotel: boolean;
    lodging_list_index: number;
    lodging_id: string;
    city_name: string;
    name: string;
    country: string;
  };
  encryptedProperties?: string | undefined;
};

export const fallbackHotelShopTrackingProperties = {
  properties: {
    hopper_preferred_hotel: false,
    lodging_list_index: 0,
    lodging_id: "",
    city_name: "",
    name: "",
    country: "",
  },
};

export type HotelShopAvailabilityTrackingProperties = {
  properties: {
    advance: number;
    check_in_date: string;
    check_out_date: string;
    los: number;
    adults_count: number;
    children_count: number;
  };
};

export const fallbackHotelShopAvailabilityTrackingProperties = {
  properties: {
    advance: 0,
    check_in_date: "",
    check_out_date: "",
    los: 0,
    adults_count: 0,
    children_count: 0,
  },
};

export const fallbackHotelAvailabilityTrackingProperties = {
  properties: {
    advance: 0,
    check_in_date: "",
    check_out_date: "",
    los: 0,
    number_of_filters_applied: 0,
    number_of_properties: 0,
    sort_order: HotelSortOptionEnum.Price,
    adults_count: 0,
    children_count: 0,
  },
};

export type HotelAvailabilityTrackingProperties = {
  properties: {
    advance: number;
    check_in_date: string;
    check_out_date: string;
    los: number;
    number_of_filters_applied: number;
    number_of_properties: number;
    sort_order: HotelSortOptionEnum;
    adults_count: number;
    children_count: number;
  };
};

export type HotelShoppedProductTrackingProperties = {
  search: HotelShopAvailabilityTrackingProperties;
  hotel: HotelShopTrackingProperties;
  room: HotelRoomTrackingProperties;
  roomRate: HotelRoomRateTrackingProperties;
};

export const fallbackHotelShoppedProductTrackingProperties = {
  search: fallbackHotelShopAvailabilityTrackingProperties,
  hotel: fallbackHotelShopTrackingProperties,
  room: fallbackHotelRoomTrackingProperties,
  roomRate: fallbackHotelRoomRateTrackingProperties,
};
