import { useCallback, useMemo, useState } from "react";
import type { FlightKey } from "@b2bportal/air-price-watch-api";
import type { Passengers, PredictionCopy } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState, WatchState } from "@hopper-b2b/types";
import { emailRegex } from "@hopper-b2b/utilities";

import {
  B2BTextField,
  IconComponent,
  IconName,
  MobilePopoverCard,
  PriceWatchCardToggle,
  Slot,
} from "../../../index";
import "./styles.scss";

export interface PriceWatchEntryCardProps {
  predictionCopy?: PredictionCopy;
  createWatch: (email: string) => void;
  deleteWatch: () => void;
  setCreateWatchCallState: (callState: CallState) => void;
  hasUnsupportedPredictionFilters: boolean;
  bellIcon: any;
  email: string;
  onEmailChange: (email: string) => void;
  watching: boolean;
  onWatchingChange: (isWatching: boolean) => void;
  priceWatchAlertKey?: FlightKey;
  paxCount?: Passengers;
}

export const PriceWatchEntryCard = (props: PriceWatchEntryCardProps) => (
  <Slot
    id="price-watch-entry-card"
    component={<PriceWatchEntryCardInternal {...props} />}
    {...props}
  />
);

const PriceWatchEntryCardInternal = (props: PriceWatchEntryCardProps) => {
  const {
    predictionCopy,
    createWatch,
    deleteWatch,
    setCreateWatchCallState,
    hasUnsupportedPredictionFilters,
    bellIcon,
    email,
    onEmailChange,
    watching,
    onWatchingChange,
  } = props;
  const { t } = useI18nContext();
  const [watchModalOpen, setWatchModalOpen] = useState(false);

  const emailError = useMemo(() => {
    return emailRegex.test(email) || email === ""
      ? ""
      : t("contactInfoEmailError");
  }, [email, t]);

  const getPriceWatchCopy = useCallback(
    (
      watchState: WatchState,
      hasUnsupportedPredictionFilters: boolean,
      copy?: PredictionCopy
    ): string => {
      if (copy) {
        if (hasUnsupportedPredictionFilters) {
          return t("priceWatch.unsupportedFilterCopy");
        }
        switch (watchState) {
          case WatchState.JustWatched:
            if (copy.recommendationBodyJustWatched.length > 1) {
              return copy.recommendationBodyJustWatched[
                copy.recommendationBodyJustWatched.length - 1
              ];
            } else {
              return copy.recommendationBodyJustWatched[0];
            }
          case WatchState.Watched:
            if (copy?.recommendationBodyWatching.length > 1) {
              return copy.recommendationBodyWatching[
                copy.recommendationBodyWatching.length - 1
              ];
            } else {
              return copy.recommendationBodyWatching[0];
            }
          case WatchState.NotWatching:
            if (copy?.recommendationBody.length > 1) {
              return copy.recommendationBody[
                copy?.recommendationBody.length - 1
              ];
            } else {
              return copy.recommendationBody[0];
            }
        }
      } else {
        return t("priceWatch.copy");
      }
    },
    [t]
  );

  const onClose = useCallback(
    (_, reason: "backdropClick" | "escapeKeyDown" | null) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      onWatchingChange(watching);
      setWatchModalOpen(false);
      setCreateWatchCallState(CallState.NotCalled);
    },
    [onWatchingChange, watching, setCreateWatchCallState]
  );

  const toggleWatch = useCallback(() => {
    if (watching) onWatchingChange(!watching);
    !watching ? setWatchModalOpen(true) : deleteWatch();
  }, [deleteWatch, onWatchingChange, watching]);

  const onCreateWatch = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const value = event.target[0].value;
      if (emailRegex.test(value)) {
        onWatchingChange(true);
        createWatch(value);
        setWatchModalOpen(false);
      }
      onEmailChange(value);
    },
    [createWatch, onEmailChange, onWatchingChange]
  );

  return (
    <>
      <PriceWatchCardToggle
        className="price-watch-entry-card-toggle"
        title={t("priceWatch.watchTrip")}
        description={t("priceWatch.copyAlt")}
        onClick={toggleWatch}
        isWatching={watching}
        iconSrc={bellIcon}
      />
      <MobilePopoverCard
        open={watchModalOpen}
        onClose={onClose}
        className="flight-watch-opt-in-popup half-sheet"
        contentClassName="flight-watch-opt-in-content-wrapper"
        headerElement={<span>{t("priceWatch.title")}</span>}
        topLeftButton={
          <button
            className="half-sheet-close"
            onClick={() => onClose(null, null)}
          >
            <IconComponent
              className="half-sheet-close-icon"
              name={IconName.Close}
            />
          </button>
        }
      >
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html:
                getPriceWatchCopy(
                  watching ? WatchState.Watched : WatchState.NotWatching,
                  hasUnsupportedPredictionFilters,
                  predictionCopy
                ) || "",
            }}
          ></span>
        </p>
        <form onSubmit={onCreateWatch}>
          <B2BTextField
            className="email-input-field"
            id="watch-email"
            label={`${t("email")}`}
            errorHelper={emailError}
            type="email"
            defaultValue={email}
          />
          <button type="submit">{t("save")}</button>
        </form>
      </MobilePopoverCard>
    </>
  );
};
