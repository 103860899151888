import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { removeTimezone } from "@hopper-b2b/utilities";

export const DEFAULT_COUNTRY = {
  twoLetterCountryCode: "US",
  threeLetterCountryCode: "USA",
  flag: "🇺🇸",
  phonePrefix: "1",
};
/**
 * This is used to turn a `2024-05-22` date into an absolute date
 * The timezone needs to be removed otherwise everything west of UTC will come out the
 * previous day with new Date() constructor
 *
 * Issues Examples:
 * new Date(`2024-05-22`) => Tue May 21 2024 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date(`2024-05-22`).toISOString() => 2024-05-22T00:00:00.000Z //This still has the timezone
 *
 * @param dateString
 */
export const dateStringToNewDate = (
  dateString: string,
  keepTimezone?: boolean
): Date =>
  keepTimezone
    ? new Date(new Date(dateString).toISOString())
    : new Date(removeTimezone(new Date(dateString).toISOString()));

export const customFormatDateTime = (
  date: Date,
  locale: string,
  defaultFormat: DateTimeFormatStyle,
  customFormat?: (date: Date) => string
) =>
  customFormat
    ? customFormat(date)
    : formatDateTime(date, locale, defaultFormat);

export const ACCEPT_LANGUAGE_HEADER = "Accept-Language";

const LANGUAGE_FAMILY_REGEX = /([a-z]*)\W?/i;

/**
 * Returns just the language family, excludes country example: `en-US` -> `en`
 *
 * TODO: Use of regex to do this is problematic becuase:
 *  - It does not try to validate the language tag so "xx-YY" will return "xx" even though "xx" is not a valid language code.
 *  - It does not handle script, for example zh-Hant-CN will return zh, but zh-Hant or zh-TW are more appropriate.
 *
 * We should consider use of Intl.Locale. Intl.Locale even though it doesn't handle extensions and variants.
 */
export const getLanguageFamily = (locale: string): string => {
  const matches = locale.match(LANGUAGE_FAMILY_REGEX);
  return matches ? matches[1] : locale;
};

/**
 * Returns an array of languages to be used in the Accept-Language header.
 *
 * TODO: This returns the original locale and language family. This is a simplistic apparoch and may not
 * be ideal because we may want to have more intermediate value, for example:
 * - If the locale is "en-US-x-variant" we may want to return [en-US-x-variant, en-US, en].
 * - If the locale is "zh-Hant-TW" we may want to return [zh-Hans-TW, zh-Hans, zh-TW, zh]. Maintaining script and region is important for some languages such as Chinese.
 * - If locale is invalid, return [en-US, en] as default.
 *
 * If we decide to fix this please take a look at [1] to see how we handle fallback languages.
 *
 * [1] https://github.com/hopper-org/b2bportal/blob/master/tools/generate-translation-strings.ts#L131-L140
 */
export const getAcceptLanguagesFromLocale = (locale: string): string[] => {
  const languageFamily = getLanguageFamily(locale);
  if (!languageFamily || languageFamily === locale) {
    return [locale];
  }
  return [locale, languageFamily];
};
