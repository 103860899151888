import { type ButtonHTMLAttributes, forwardRef } from "react";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import defaultStyles from "./Button.module.scss";

export enum ButtonTypeEnum {
  Primary = "primary",
  Secondary = "secondary",
  Link = "link",
  /**
   * Wrap variant when you want the button to wrap a component without applying
   * standard button styles. Useful for creating interactive containers
   * that behave like buttons but maintain custom styling.
   */
  Wrap = "wrap",
}

export interface ButtonComponentProps
  extends PlatformComponentProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonTypeEnum;
}

const COMPONENT_KEY = CoreUiComponents.Button;
export const Button = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  ({ className, children, buttonType, ...restProps }, ref) => {
    const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
    const [block] = useModuleBEM(styles, COMPONENT_KEY, [buttonType]);

    return (
      <button className={clsx(block, className)} {...restProps} ref={ref}>
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";
