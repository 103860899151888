import {
  FlightItinerarySegmentStatus,
  type BookedFlightItinerary,
  type FlightItinerarySlice,
  type ScheduleChange,
  type FlightItinerarySegment,
} from "@b2bportal/air-booking-api";
import type {
  ScheduleChangeDiffModel,
  SegmentKey,
  SliceChangeContext,
  SliceKey,
} from "@b2bportal/core-types";
import { TimeGroupingEnum } from "@hopper-b2b/types";
import dayjs from "dayjs";

import { getDepartureSlice, getReturnSlice, getSliceDirection } from "./slice";

export const SCHEDULE_CHANGE_AFTER_MINS = 0;
export const SCHEDULE_CHANGE_BEFORE_MINS = 59;
export const SCHEDULE_CHANGE_PREF_RANGES = {
  [TimeGroupingEnum.EARLY_MORNING]: {
    afterHours: 0, // 12 AM
    beforeHours: 4, // 4 AM
  },
  [TimeGroupingEnum.MORNING]: {
    afterHours: 5, // 5 AM
    beforeHours: 11, // 11 AM
  },
  [TimeGroupingEnum.AFTERNOON]: {
    afterHours: 12, // 12 PM
    beforeHours: 17, // 5 PM
  },
  [TimeGroupingEnum.EVENING]: {
    afterHours: 18, // 6 PM
    beforeHours: 23, // 11 PM
  },
  anytime: {
    // default case
    afterHours: 0, // 12 AM
    beforeHours: 23, // 11 PM
  },
};
export const SCHEDULE_CHANGE_PREF_FORMAT = "YYYY-MM-DDTHH:mm:ss";

/**
 * Use with `getSegmentScheduleChangeKey` and `getSliceScheduleChangeKey`
 * @param scheduleChange
 * @returns
 */
export const getScheduleChangeDiffs = (
  itinerarySlices: FlightItinerarySlice[],
  scheduleChange?: ScheduleChange
) => {
  if (scheduleChange == null) {
    return undefined;
  }

  const { expiry, prev = [], next = [], severity } = scheduleChange;
  const diffModel: ScheduleChangeDiffModel = {
    changes: {},
    expiry,
    severity,
  };

  for (let i = 0; i < prev.length; i += 1) {
    const sliceKey = getSliceScheduleChangeKey(prev[i]);
    const { segments: updatedSegments } = next[i];

    diffModel.changes[sliceKey] = {
      severity,
      changes: {},
      direction: getSliceDirection(prev[i], itinerarySlices),
      updatedSlice: next[i],
    };

    for (let j = 0; j < updatedSegments.length; j += 1) {
      const updatedSegment = updatedSegments[j];
      const segmentKey = getSegmentScheduleChangeKey(updatedSegment);

      diffModel.changes[sliceKey].changes[segmentKey] = updatedSegment;
    }
  }

  return diffModel;
};

/**
 * Converts the form selections model into the API request payload model
 * @param selectedTimePrefs
 * @param departureDate
 */
export const getScheduleChangePrefs = (
  selectedTimePrefs: TimeGroupingEnum[],
  departureDate: string
) => {
  return selectedTimePrefs.map((timePref) => {
    const preference =
      SCHEDULE_CHANGE_PREF_RANGES[timePref] ??
      SCHEDULE_CHANGE_PREF_RANGES.anytime;
    const depDate = dayjs(departureDate);

    return {
      departAfter: depDate
        .set("hour", preference.afterHours)
        .set("minutes", SCHEDULE_CHANGE_AFTER_MINS)
        .format(SCHEDULE_CHANGE_PREF_FORMAT),
      departBefore: depDate
        .set("hour", preference.beforeHours)
        .set("minutes", SCHEDULE_CHANGE_BEFORE_MINS)
        .format(SCHEDULE_CHANGE_PREF_FORMAT),
    };
  });
};

export const getSegmentChangeContext = (
  sliceChangeContext: SliceChangeContext,
  segment: FlightItinerarySegment
): FlightItinerarySegment | undefined => {
  const segmentKey = getSegmentScheduleChangeKey(segment);
  const segmentChangeContext = sliceChangeContext.changes[segmentKey];

  return segmentChangeContext;
};

export const getSegmentScheduleChangeKey = (
  segment: FlightItinerarySegment
): SegmentKey => {
  const { origin, destination } = segment;

  return `${origin.locationCode}-${destination.locationCode}`;
};

export const getSliceChangeContext = (
  slice: FlightItinerarySlice,
  diffModel?: ScheduleChangeDiffModel
): SliceChangeContext | undefined => {
  const sliceKey = getSliceScheduleChangeKey(slice);

  return diffModel?.changes[sliceKey];
};

export const getSliceScheduleChangeKey = (
  slice: FlightItinerarySlice
): SliceKey => {
  const { segments } = slice;
  const firstSegmentKey = getSegmentScheduleChangeKey(segments[0]);
  const lastSegmentKey = getSegmentScheduleChangeKey(
    segments[segments.length - 1]
  );

  return `${firstSegmentKey}::${lastSegmentKey}`;
};

export const sliceHasScheduleChange = (
  bookedItinerary: BookedFlightItinerary,
  isOutgoing = true
) => {
  const slice = isOutgoing
    ? getDepartureSlice(bookedItinerary)
    : getReturnSlice(bookedItinerary);

  if (slice) {
    return slice.segments.some(
      (s) =>
        s.status === FlightItinerarySegmentStatus.ConfirmedPendingNewChange ||
        s.status === FlightItinerarySegmentStatus.UnMapped ||
        s.status === FlightItinerarySegmentStatus.UnMappedPersisted
    );
  }

  return false;
};
