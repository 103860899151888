import type {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySlice,
  SeatedPassengers,
} from "@b2bportal/air-booking-api";
import { createAction } from "@reduxjs/toolkit";
import {
  IdEnum,
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@b2bportal/core-types";
import {
  ExchangeScenario,
  ExchangeScenarioEnum,
} from "@b2bportal/air-exchange-api";
import {
  getDepartureSlice,
  getReturnSlice,
  getSliceDepartureDate,
} from "@b2bportal/core-utilities";

/**
 * Converts an airport object to a trip terminus format
 * @param airport - The airport data to convert
 * @returns A formatted trip terminus object
 */
const convertAirportToTerminus = (airport: Airport): ITripTerminus => ({
  id: {
    code: {
      regionType: "airport",
      code: airport.code,
    },
    Id: IdEnum.Flight,
  },
  label: airport.name,
  trackingProperties: {},
  trackingPropertiesV2: {},
  categoryLabel: "Airports",
});

/**
 * Calculates passenger counts from seated passenger data
 * @param passengers - The seated passengers information
 * @returns Counts of different passenger types
 */
const getPassengerCountsFromSeatedPassengers = (
  passengers: SeatedPassengers
): IPassengerCounts => ({
  adultsCount:
    passengers.alone.filter((p) => p.type === "ADT").length +
    passengers.withLapInfants.length,
  childrenCount: passengers.alone.filter((p) => p.type === "CHD").length,
  infantsInSeatCount: passengers.alone.filter((p) => p.type === "INS").length,
  infantsOnLapCount: passengers.withLapInfants.length,
});

/**
 * Gets departure and return dates based on flight slices and exchange scenario
 * @param departureSlice - The first slice of the itinerary
 * @param returnSlice - The last slice of the itinerary (if exists)
 * @param useDates - Whether to use the dates from the itinerary
 * @returns Object containing departure and return dates
 */
const getDates = (
  departureSlice: FlightItinerarySlice,
  returnSlice: FlightItinerarySlice | undefined,
  useDates: boolean
) => ({
  departureDate: useDates ? getSliceDepartureDate(departureSlice) : undefined,
  returnDate:
    useDates && returnSlice != null
      ? getSliceDepartureDate(returnSlice)
      : undefined,
});

/**
 * Creates flight search form values from an existing itinerary.
 * For FTC exchange scenarios, dates from the original itinerary are not used
 * as they may be in the past.
 */
export const setSearchFormFromItinerary = createAction(
  "flightSearch/setSearchFormFromItinerary",
  ({
    itinerary,
    exchangeScenario,
    airports,
  }: {
    itinerary: BookedFlightItineraryWithDepartureTime;
    exchangeScenario: ExchangeScenario;
    airports: Record<string, Airport>;
  }) => {
    const sliceCount = itinerary.bookedItinerary.travelItinerary.slices.length;
    const departureSlice = getDepartureSlice(itinerary.bookedItinerary);
    const returnSlice = getReturnSlice(itinerary.bookedItinerary);
    const useDates =
      exchangeScenario.ExchangeScenario !== ExchangeScenarioEnum.Ftc;

    return {
      payload: {
        searchFormValues: {
          tripCategory:
            sliceCount > 1 ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY,
          origin: convertAirportToTerminus(
            airports[departureSlice.segments[0].origin.locationCode]
          ),
          destination: convertAirportToTerminus(
            airports[departureSlice.segments[0].destination.locationCode]
          ),
          ...getDates(departureSlice, returnSlice, useDates),
          passengerCounts: getPassengerCountsFromSeatedPassengers(
            itinerary.bookedItinerary.passengers
          ),
        },
      },
    };
  }
);
