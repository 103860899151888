import type { ComponentType, ReactElement } from "react";
import type { Product } from "@b2bportal/purchase-api";
import type * as H from "history";
import type { StateValue, send as xstateSend } from "xstate";

import type { FiatPrice, RewardsPrice } from "../common";
import type { FlightClientAssetProps } from "../flights-module";
import type { PaymentTypeEnum } from "../trips-module";

type ComponentName = string;

interface CheckoutComponentDetails {
  component: ComponentType<any>;
  state: string;
  className?: string;
  props?: Record<string, any>;
  // By default, controlType will be "hide", component will be hidden
  // if corresponding state is not equal to xstate's parent state.
  // If "persist" is set, then the component will be rendered regardless
  // of the xstate's current state.
  controlType?: "hide" | "persist";
}

export type CheckoutComponentMap = Record<
  ComponentName,
  CheckoutComponentDetails
>;

// TODO: fix types
type Actions = Record<string, any>;
type Guards = Record<string, any>;
type Context = Record<string, any>;
type Services = Record<string, any>;

export interface CheckoutProps {
  stateMachine: any;
  Component: React.ComponentType;
  actions: Actions;
  context?: Context;
  getInitialContext?: () => Context;
  services?: Services;
  guards?: Guards;
  onLoad?: () => void;
  onCleanUp?: () => void;
  // TODO: fix any
  sessionInfo?: any;
  clientAssets?: FlightClientAssetProps;
  onStateValueChange?: (state: StateValue, history?: H.History) => void;
  onPathnameChange?: (
    state: StateValue,
    history: H.History,
    send: typeof xstateSend
  ) => void;
  validateContext?: (ctx: any) => boolean;
}

export interface ILineItemProps {
  lineTitle?: string;
  customLineItemText?: string;
  customItemDescriptionText?: string;
  baseAmount?: number;
  // Use this if you want to conserve the original currency
  // The baseAmount if you want to use the default currency from shop, probably USD
  baseAmountFiat?: FiatPrice;
  taxesAndFees?: number;
  seats?: number;
  frozenPrice?: boolean;
  cfarFee?: number;
  products?: Record<string, FiatPrice>;
  useHtml?: boolean;
  className?: string;
  id?: string;
  // Test data identifier for easier testing
  dataTestId?: string;
}

export interface ContactInformation {
  countryCode: string;
  phoneNumber: string;
  email: string;
}

export enum PhoneNumberValidatorEnum {
  CUSTOM_ERROR = "customError",
  DEFAULT_ERROR = "default",
  VALID = "valid",
  NOT_VALIDATED = "notValidated",
}

export enum SummaryLineItemBaseEnum {
  TOTAL = "total",
  REWARDS = "rewards",
  PAYMENT = "payment",
  CUSTOM = "custom",
  MARKDOWN = "markdown",
  PRICE_FREEZE_DISCOUNT = "priceFreezeDiscount",
  CHFAR_DISCOUNT = "chfarDiscount",
  PRODUCT = "product",
  VOUCHER_DISCOUNT = "voucherDiscount",
  CREDIT_PAYMENT = "creditPayment",
  PROMOTION_DISCOUNT = "promotionDiscount",
  CARD_PAYMENT = "cardPayment",
  AMOUNT_DUE = "amountDue",
}

interface ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum;
  fiatPrice: FiatPrice;
}

export interface PricingBreakdownTitles {
  baseFareText: string;
  cfarText?: string;
  disruptionProtectionText?: string;
  frozenBaseFareText?: string;
  rewardsAccountText: (lastFour?: string) => string;
  rewardsAppliedText: (accountName: string) => string;
  seatsText?: string;
  taxesAndFeesText: string;
  totalText: string;
  travelOfferText?: string;
  travelCreditsText?: string;
}

export interface ITotalLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.TOTAL;
  label?: string;
  rewardsPrice?: RewardsPrice;
  details?: string;
}

export interface IPaymentLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.PAYMENT;
  label?: string;
  rewardsPrice?: RewardsPrice;
  lastFour?: string;
}

export interface ICustomLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.CUSTOM;
  rewardsPrice?: RewardsPrice;
  className?: string;
  icon?: ReactElement;
  label?: string;
}

export interface IMarkdownLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.MARKDOWN;
  className?: string;
  label: string;
  markdownType: PaymentTypeEnum;
  redemptionRate?: number;
  rewardsPrice?: RewardsPrice;
}

export interface IRewardsLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.REWARDS;
  rewardsAccountName: string;
  rewardsPrice: RewardsPrice;
}

export interface IPriceFreezeDiscountLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.PRICE_FREEZE_DISCOUNT;
  label?: string;
}

export interface IChfarDiscountLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.CHFAR_DISCOUNT;
  label?: string;
}

export interface IVoucherDiscountLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.VOUCHER_DISCOUNT;
  label?: string;
}
export interface ICreditPaymentLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.CREDIT_PAYMENT;
  label?: string;
}

export interface IPromotionDiscountLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.PROMOTION_DISCOUNT;
  label?: string;
}
export interface IProductLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.PRODUCT;
  label?: string;
  product: Product;
  perPaxPrice?: FiatPrice;
}

export interface ICardPaymentLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.CARD_PAYMENT;
  label?: string;
  lastFour?: string;
}

export interface IAmountDueLineItem extends ISummaryLineItemBase {
  type: SummaryLineItemBaseEnum.AMOUNT_DUE;
}

export type ISummaryLineItem =
  | ITotalLineItem
  | IPaymentLineItem
  | ICardPaymentLineItem
  | IRewardsLineItem
  | ICustomLineItem
  | IPriceFreezeDiscountLineItem
  | IChfarDiscountLineItem
  | IMarkdownLineItem
  | IProductLineItem
  | IVoucherDiscountLineItem
  | ICreditPaymentLineItem
  | IPromotionDiscountLineItem
  | IAmountDueLineItem;

export interface IFlyerOption {
  program: string;
  value: string;
  label?: JSX.Element;
}

export * from "./tracking";
