import type { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import type { FabProps } from "@material-ui/core";

import type { TranslationLanguage } from "../i18n";

export interface BrandAttributes {
  clientName: ClientName;
  merchantPayments: string;
  contactSupport: ContactSupportConfig;
  currencies: Array<{
    locale: string;
    code: string;
    symbol?: string;
    highInflationCurrency?: boolean;
    symbolOverride?: string;
    localeOverride?: string;
  }>;
  supportedLanguages?: Array<{
    key: string;
    name: string;
    label: string;
  }>;
  lodgingCardPriceRounding?: number;
  flightShopCardType?: FlightShopCardType;
  flightShopDefaultSort?: FlightShopDefaultSort;
  adaChat?: {
    answerIds: {
      delayOrMissedConnection: string;
    };
  };
  hidePaymentZipCode?: boolean;
}

export interface TranslatableBrandAttributes {
  portalName: string;
  urls: {
    termsAndConditions: string;
    privacyPolicy: string;
    updateBalance?: string;
    notifications?: string;
    htsAddOns?: string;
    disruptionTerms?: string;
    benefitsFromAirline?: string;
    priceFreezeTerms?: string;
    cfarTerms?: string;
    chfarTerms?: string;
    missedConnectionTerms?: string;
    helpCenterBaseUrl?: string;
    confirmation?: string;
  };
  preferredCountryCode: string;
  /**
   * @description Used to auto-populate contact info form
   */
  preferredAreaCode: string;
  poweredBy?: string;
  home?: {
    title: string;
    subtitle: string;
    modal?: {
      title: string;
      subtitle: string;
      list: string[];
    };
  };
  loading?: {
    flightSearch: {
      subtitle: string;
    };
  };
  // @deprecated calendarMonthFormat is to use with the legacy flights lib and ui lib
  // use calendarMonthFormatStyle with core-ui and core-flights
  calendarMonthFormat?: string;
  calendarMonthFormatStyle?: DateTimeFormatStyle;
  calendarDateLabelFormat?: string;
  timeFormat?: string;
  customDateTimeFormats?: {
    tripsList?: (date: Date) => string; // Hotel and flight cards on trips list
    hotelSummary?: (date: Date) => string; // Hotel summary info on hotel details, room details and hotel checkout screens
    calendar?: (date: Date) => string; // Calendar header date
    calendarDay?: (date: Date) => string; // Calendar weekday label
    fareDetails?: (date: Date) => string; // Fare details screen date label
    shopEditButton?: (date: Date) => string; // Shop list edit dates button
  };
}

export interface AIChatFabConfig {
  variant?: FabProps["variant"];
}

export interface BrandNames
  extends BrandAttributes,
    TranslatableBrandAttributes {
  currency: {
    locale: string;
    code: string;
    symbol?: string;
    highInflationCurrency?: boolean;
    symbolOverride?: string;
  };
}

export interface Branding extends BrandAttributes {
  default?: string;
  translation: {
    [lang in TranslationLanguage]?: TranslatableBrandAttributes;
  };
}

export interface ContactSupportConfig {
  aiChat?: AIChatFabConfig;
  chatAvailability: ChatAvailability;
  /**
   * @description Formatted international Customer Support number
   */
  displayIntlSupportNumber?: string;
  /**
   * @description Formatted Customer Support number
   */
  displaySupportNumber: string;
  /**
   * @description Formatted Customer Support number with letters
   */
  displaySupportNumberPhonewords?: string;
  /**
   * @description Raw international Customer Support number
   */
  intlSupportNumber?: string;
  phoneAvailability?: PhoneSupportAvailability;
  /**
   * @description Raw Customer Support number
   */
  supportNumber: string;
}

export interface PhoneSupportAvailability {
  air?: PhoneSupportVerticalConfig;
  general?: PhoneSupportVerticalConfig;
  ground?: PhoneSupportVerticalConfig;
  hotel?: PhoneSupportVerticalConfig;
  homes?: PhoneSupportVerticalConfig;
}

export interface PhoneSupportVerticalConfig {
  /**
   * @description The start hour in UTC. Current hour must be **greater than or equal to (>=)** this number
   */
  serviceStartUtc: number;
  /**
   * @description The end hour in UTC. Current hour must be **less than (<)** this number
   */
  serviceEndUtc: number;
}

export enum ChatAvailability {
  All = "All",
  None = "None",
  VipOnly = "VipOnly",
}

export enum FlightShopCardType {
  MINI = "mini",
  REGULAR = "regular",
}

export enum FlightShopDefaultSort {
  Recommended = "Recommended",
  TagsThenPrice = "TagsThenPrice",
}

export enum ClientName {
  COMMBANK_AU = "commbank-au",
  HOPPER = "hopper",
  HSBC = "hsbc-sg",
  HYATT = "hyatt",
  MARRIOTT = "marriott",
  JETBLUE = "jetBlue",
  NEQUI = "nequi",
  NUBANK = "nubank",
  TRIPADVISOR = "tripadvisor",
  UBER = "uber",
  VOLARIS = "volaris",
  FLAIR = "flair",
  LLOYDS = "lloyds",
  BOOTSTRAP = "bootstrap",
  VIRGIN_AU = "virgin-au",
  BANORTE = "banorte",
  SMCC = "smcc",
  LOTTE = "lotte",
  CAPONE = "capone",
}
